import React, { useEffect, useState } from "react"
import Select from "react-select";
import axios from "axios";
import UserTable from "./userTable";

const TeacherSelect = ({ town, teachers, town_teachers }) => {
  let [teacherOptions, setTeacherOptions] = useState(null)
  let [teacher, setTeacher] = useState(null)
  let [townTeachers, setTownTeachers] = useState(town_teachers)

  useEffect(() => {
    let unselectedTeachers = teachers.filter(teacher => !townTeachers.map(teacher => teacher.id).includes(teacher.id))
    let teacherOptions = unselectedTeachers.map(teacher => {
      return { value: teacher.id, label: `${teacher.first_name} ${teacher.last_name}` }
    })
    setTeacherOptions(teacherOptions)
  }, [townTeachers])

  const addTeacher = (teacher) => {
    teacher && axios.post(`/admin/towns/${town.id}/add_teacher`, {
      id: town.id, teacher_id: teacher.value
    })
      .then(function (response) {
        setTownTeachers(response.data)
        setTeacher('')
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const removeTeacher = (teacher) => {
    axios.post(`/admin/towns/${town.id}/remove_teacher`, {
      id: town.id, teacher_id: teacher.id
    })
      .then(function (response) {
        setTownTeachers(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (<div className="px-4 sm:px-6 lg:px-8 my-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Teachers</h1>
          <p className="mt-2 text-sm text-gray-700">All the teachers who can access this town</p>
        </div>
      </div>
      <div className='flex flex-row items-center gap-x-4'>
        <Select
          value={teacher}
          onChange={(selectedOption) => setTeacher(selectedOption)}
          options={teacherOptions}
          className='w-3/4'
        />
        <button onClick={() => addTeacher(teacher)}
                className='block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
          Add Teacher
        </button>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <UserTable users={townTeachers} removeUser={removeTeacher} />
          </div>
        </div>
      </div>
    </div>)
}

export default TeacherSelect
