import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { formatPrice } from "../../shared/utils";

export const TownContext = createContext({
  town: null,
  setTown: null,
  townLogo: null,
  teacher: null,
  properties: null,
  property: null,
  setProperty: null
})

const Town = ({ selectedProperty, children }) => {
  let [teacher, setTeacher] = useState()
  let [town, setTown] = useState()
  let [townLogo, setTownLogo] = useState()
  let [properties, setProperties] = useState()
  let [property, setProperty] = useState(selectedProperty)
  let { townId } = useParams()

  useEffect(() => {
    axios.get(`/teachers/towns/${townId}.json`).then(res => setTown(res.data))
  }, [])

  useEffect(() => {
    if (town) {
      axios.get(`/teachers/towns/${town.id}/logo`).then(res => setTownLogo(res.data.logo))
    }
  }, [town])

  useEffect(() => {
    town && axios.get(`/teachers/towns/${town.id}/properties.json`).then(res => setProperties(res.data))
    town && axios.get(`/teachers/me`, { params: { town_id: town.id } }).then(res => setTeacher(res.data))
  }, [town])

  return (
    <TownContext.Provider value={{
      town: town,
      townLogo: townLogo,
      setTown: setTown,
      teacher: teacher,
      properties: properties,
      property: property,
      setProperty: setProperty
    }}>
      {town && teacher && <div className="flex flex-row justify-between items-center px-9 py-2 bg-blue-900 text-white">
        <div className="flex flex-row gap-x-2">
          <h3 className="text-lg uppercase">
            {town.name}
          </h3>
          <div
            className={`${town.is_market_open ? 'bg-green-700' : 'bg-red-700'} rounded-lg px-2 text-white uppercase text-sm font-semibold items-center justify-center flex`}>
            <span>Market {town.is_market_open ? 'OPEN' : 'CLOSED'}</span></div>
          <div
            className="bg-yellow-700 rounded-lg px-2 text-white uppercase text-sm font-semibold items-center justify-center flex">
            <span>Step {town.step}</span></div>
        </div>
        <div className="flex flex-row gap-x-9 ml-20 items-center">
          <div className="font-bold text-yellow-600">
            {formatPrice(town.net_worth)}
          </div>
          <div>
            <Link to={`/teachers/towns/${townId}`}>Map</Link>
          </div>
          <div>
            <Link to={`/teachers/towns/${townId}/leaderboards`}>Leaderboard</Link>
          </div>
          <div>
            <Link to={`/teachers/towns/${townId}/markets`}>Market</Link>
          </div>
          <div>
            <a href='/teachers/sign_out' data-method='delete'>Logout</a>
          </div>
          {townLogo &&
            <img alt='town logo' src={townLogo} className='rounded-full object-cover object-center h-16' />}
        </div>
      </div>}

      <div className="lg:flex lg:flex-row lg:flex-auto lg:flex-shrink-0 antialiased text-gray-800 w-screen">
        {children}
      </div>
    </TownContext.Provider>
  )
}

export default Town
