import React, { useEffect, useState } from "react";
import { formatPrice } from "../utils";
import axios from "axios";

const TownLeader = ({ town, rank, baseRoute }) => {
  const [logo, setLogo] = useState()

  useEffect(() => {
    axios.get(`${baseRoute}/towns/${town.id}/logo`).then(res => setLogo(res.data.logo))
  }, [])

  return <div className='flex flex-row'>
    <div className='w-1/4 items-center flex flex-col justify-center'>
      <div className='text-xl text-center font-bold text-gray-500' style={{ fontSize: '4rem' }}>{rank}</div>
    </div>
    <div className='flex flex-row gap-x-8 bg-blue-900 rounded-md p-4 m-4 w-3/4'>
      <img
        src={logo}
        alt={town.name}
        className='w-20 h-20 rounded-xl object-cover object-center'
      />

      <div className='flex flex-col p-4'>
        <div className='text-white font-bold'>{town.name}</div>
        <div className='text-yellow-400 font-bold text-sm'>{formatPrice(town.net_worth)}</div>
      </div>
    </div>
  </div>
}

export default TownLeader
