import React from "react";

const StorageInfo = ({ title, area, totalArea}) => {

  return (
      <div className='flex flex-row gap-x-24'>
        <p className='font-semibold w-1/3'>{title} - {area} ft<sup>2</sup></p>
        <div className="w-2/3 relative pt-1">
          <div className="overflow-hidden h-2 mb-4 text-xs flex bg-blue-200">
            <div style={{ width: `${area/totalArea * 100}%` }}
                 className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-900"></div>
          </div>
        </div>
      </div>
  )
}

export default StorageInfo
