import React from "react";
import { formatPrice } from "../shared/utils";

const UserList = ({ users, removeUser }) => {
  return (
    users.map(user => {
      return (
        <tr key={user.id}>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
            {`${user.first_name} ${user.last_name}`}
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {user.email}
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
            {formatPrice(user.current_balance)}
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
            {formatPrice(user.net_worth)}
          </td>
          <td>
            <button onClick={() => removeUser(user)}>
              Remove
            </button>
          </td>
        </tr>
      )
    })
  )
}


const StudentTable = ({ users, removeUser }) => {
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
      <tr>
        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Name</th>
        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
        <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-right">Current Balance</th>
        <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-right">Net Worth</th>
        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
          <span className="sr-only">Actions</span>
        </th>
      </tr>
      </thead>
      <tbody>
      {users && <UserList users={users} removeUser={removeUser} />}
      </tbody>
    </table>
  )
}

export default StudentTable
