import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons'

const LoadingSpinner = () => {
  return (
    <div className='w-full flex flex-col my-72 items-center text-2xl'>
      <FontAwesomeIcon
        icon={faCircleNotch}
        spin
        className='text-yellow-600 text-8xl'
      />
    </div>
  )
}

export default LoadingSpinner
