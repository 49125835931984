import AssetModal from "./assetModal";
import React, { useState } from "react";
import BuyCrops from "./buyCrops";

const CropsRow = ({ property, type, town, propertyType }) => {
  let [showModal, setShowModal] = useState(false)

  return <>
    <div className='flex flex-row gap-x-6'>
      <p className='w-36'>{property.name}</p>
      <button
        onClick={() => setShowModal(true)}
        className='w-30 bg-yellow-600 rounded-md px-3 py-1 text-white'
      >Buy
      </button>
    </div>
    {showModal && <AssetModal showModal={showModal} setShowModal={setShowModal}>
      <BuyCrops town={town} propertyType={propertyType} property={property} type={type} setShowModal={setShowModal} />
    </AssetModal>}
  </>
}

export default CropsRow
