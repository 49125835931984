import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import MarketLiveStockItem from "./marketLiveStockItem";
import MarketCropsItem from "./marketCropsItem";
import MarketGoodsItem from "./marketGoodsItem";
import LoadingSpinner from "../../shared/loadingSpinner";
import { oilIcon, goldIcon, coinIcon, lumberIcon, diamondIcon, fossilIcon, buffaloIcon, flowerIcon, doubleWheatIcon, tripleWheatIcon, horseIcon, cowIcon } from "../../../../assets/images";

const Market = ({ context, isTeacher = false }) => {
  let [properties, setProperties] = useState(null)
  let [loading, setLoading] = useState(true)

  let { town } = useContext(context)

  useEffect(() => {
    if (town) {
      let url = `/students/towns/${town.id}/properties`
      if(isTeacher) {
        url = `/teachers/towns/${town.id}/properties`
      }
      town && axios.get(url)
        .then(res => {
          setProperties(res.data.filter(property => property.owned_by_me))
          setLoading(false)
        })
    }
  }, [town])

  const determiningPropertySize = (properties, propertyType) => {
    return properties.flatMap((property) => {
      return property.storage.filter((storage) => storage.storage_type == propertyType && storage.size > 0)
    })
  }
  const fencedProperties = properties && determiningPropertySize(properties, 'FENCE')
  const fieldProperties = properties && determiningPropertySize(properties, 'FIELD')
  const warehouseProperties = properties && determiningPropertySize(properties, 'WAREHOUSE')


  return (
    town ? <div className='w-full'>
      <div className='flex flex-row uppercase gap-x-2 my-3'>
        <h3 className='text-3xl'>Market</h3>
        <div
          className={`${town.is_market_open ? 'bg-green-700' : 'bg-red-700'} rounded-lg px-2 text-white uppercase text-sm font-semibold items-center justify-center flex`}>
          <span>{town.is_market_open ? 'OPEN' : 'CLOSED'}</span></div>

      </div>
      {loading ? <LoadingSpinner /> :
        <div className='flex flex-col gap-y-4 border-4 border-blue-900 rounded-lg p-4 w-full'>
          <MarketLiveStockItem type={'cattle'} propertyType={fencedProperties} properties={properties} town={town} isTeacher={isTeacher} icon={cowIcon} />
          <MarketLiveStockItem type={'horse'} propertyType={fencedProperties} properties={properties} town={town} isTeacher={isTeacher} icon={horseIcon} />
          <MarketLiveStockItem type={'buffalo'} propertyType={fencedProperties} properties={properties} town={town} isTeacher={isTeacher} icon={buffaloIcon} />
          <hr className='w-full m-2 border-4 border-gray-300' />
          <MarketCropsItem type={'wheat'} propertyType={fieldProperties} properties={properties} town={town} isTeacher={isTeacher} icon={tripleWheatIcon} />
          <MarketCropsItem type={'barley'} propertyType={fieldProperties} properties={properties} town={town} isTeacher={isTeacher} icon={doubleWheatIcon} />
          <MarketCropsItem type={'canola'} propertyType={fieldProperties} properties={properties} town={town} isTeacher={isTeacher} icon={flowerIcon} />
          <hr className='w-full m-2 border-4 border-gray-300' />
          <MarketGoodsItem type={'oil'} propertyType={warehouseProperties} properties={properties} town={town} isTeacher={isTeacher} icon={oilIcon}/>
          <MarketGoodsItem type={'gold'} propertyType={warehouseProperties} properties={properties} town={town} isTeacher={isTeacher} icon={goldIcon}/>
          <MarketGoodsItem type={'town coin'} propertyType={warehouseProperties} properties={properties} town={town} isTeacher={isTeacher} icon={coinIcon}/>
          <MarketGoodsItem type={'diamond'} propertyType={warehouseProperties} properties={properties} town={town} isTeacher={isTeacher} icon={diamondIcon}/>
          <MarketGoodsItem type={'lumber'} propertyType={warehouseProperties} properties={properties} town={town} isTeacher={isTeacher} icon={lumberIcon}/>
          <MarketGoodsItem type={'rare fossil'} propertyType={warehouseProperties} properties={properties} town={town} isTeacher={isTeacher} icon={fossilIcon}/>
        </div>}
    </div> : <></>
  )
}

export default Market
