import React, { useEffect, useState } from "react";
import axios from "axios";
import Leader from "./leader";

const TownLeaderboard = ({ town, baseRoute }) => {
  const [leaders, setLeaders] = useState()

  useEffect(() => {
    axios.get(`${baseRoute}/towns/${town.id}/leaderboards/current_town`).then(res => setLeaders(res.data))
  }, [])

  return <div className='flex flex-col w-1/3 border-8 rounded-xl border-blue-900'>
    <h2 className='w-full text-center font-semibold text-xl py-4'>{town.name} Leaderboard</h2>

    <div className='flex flex-col gap-y-8 mt-4'>
      {leaders && leaders.map((student, index) => <Leader key={student.id} student={student} rank={index + 1}
                                                          baseRoute={baseRoute} />)}
    < /div>
  </div>
}

export default TownLeaderboard
