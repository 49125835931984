import React, { useEffect, useState } from "react";
import axios from "axios";
import BuyItems from "./buyItems";

const BuyCrops = ({ town, type, setShowModal, property, propertyType }) => {
  let [currentPrice, setCurrentPrice] = useState()

  useEffect(() => {
    axios.get(`/students/towns/${town.id}/markets/crop_prices?name=${type}`).then(res => {
      setCurrentPrice(res.data[town.step - 1])
    })
  }, [])

  const buyLiveStock = (count, type) => {
    return axios.post(`/students/towns/${town.id}/properties/${property.id}/crops`, {
      item: {
        count: count,
        name: type
      }
    })
  }

  return currentPrice ?
    <BuyItems
      type={type} setShowModal={setShowModal}
      buyItems={buyLiveStock}
      currentPrice={currentPrice}
      availableLand={propertyType[0]?.size}
      property={property}
    /> : <></>
}

export default BuyCrops

