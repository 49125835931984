import React, { useContext } from 'react'
import { formatPrice } from "../shared/utils";
import Map from "../Map";
import axios from "axios";
import PropertyModal from "./propertyModal";
import { TownContext } from "./shared/townContext";
import LoadingSpinner from "../shared/loadingSpinner";

const TeacherMap = () => {
  let { town, properties, property, setProperty } = useContext(TownContext)

  const handlePlotClick = function (d) {
    axios.get(`/teachers/towns/${town.id}/properties/find?name=${d.target.__data__.plotId}`).then(res => setProperty(res.data))
  }

  return ((town && properties) ? <>
      <Map handlePlotClick={handlePlotClick} town={town} plots={properties} />
      {
        property &&
        <PropertyModal title={`${property.name} - ${formatPrice(property.price)}`} showModal={true}
                       setShowModal={() => setProperty(null)}
                       onConfirm={() => setProperty(null)}>
          <div className='flex flex-row'>
            {property.owner ?
              <div>{property.name} is owned by {property.owner.first_name}</div> :
              <div>This property is not owned by anyone yet.</div>}
          </div>
        </PropertyModal>
      }
    </> : <div className='flex flex-row items-center w-full'><LoadingSpinner /></div>
  )
}

export default TeacherMap
