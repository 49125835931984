import React, { useEffect, useState } from "react"
import Select from "react-select";
import axios from "axios";
import UserTable from "./userTable";
import StudentTable from "./studentTable";

const StudentSelect = ({ town, students, town_students }) => {
  let [studentOptions, setStudentOptions] = useState(null)
  let [student, setStudent] = useState(null)
  let [studentAccounts, setStudentAccounts] = useState(town_students)

  useEffect(() => {
    let unselectedStudents = students.filter(student => !studentAccounts.map(student => student.id).includes(student.id))
    let studentOptions = unselectedStudents.map(student => {
      return { value: student.id, label: `${student.first_name} ${student.last_name}` }
    })
    setStudentOptions(studentOptions)
  }, [studentAccounts])

  const addStudent = (student) => {
    student && axios.post(`/admin/towns/${town.id}/add_student`, {
      id: town.id, student_id: student.value
    })
      .then(function (response) {
        setStudentAccounts(response.data)
        setStudent('')
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const removeStudent = (student) => {
    axios.post(`/admin/towns/${town.id}/remove_student`, {
      id: town.id, student_id: student.id
    })
      .then(function (response) {
        setStudentAccounts(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (<div className="px-4 sm:px-6 lg:px-8 my-8">
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <h1 className="text-base font-semibold leading-6 text-gray-900">Students</h1>
        <p className="mt-2 text-sm text-gray-700">All the students in this town</p>
      </div>
    </div>
    <div className='flex flex-row items-center gap-x-4 w-full'>
      <Select
        value={student}
        onChange={(selectedOption) => setStudent(selectedOption)}
        options={studentOptions}
        className='w-3/4'
      />
      <button onClick={() => addStudent(student)}
              className='block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
        Add Student
      </button>
    </div>
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <StudentTable users={studentAccounts} removeUser={removeStudent} />
        </div>
      </div>
    </div>
  </div>)
}

export default StudentSelect
