import React, { useContext } from 'react'
import Modal from "../shared/modal";
import Map from "../Map";
import axios from "axios";
import StudentHeader from "./property/studentHeader";
import PropertySidebar from "./property/propertySidebar";
import PropertyDetails from "./property/propertyDetails";
import { TownContext } from "./shared/townContext";
import LoadingSpinner from "../shared/loadingSpinner";

const StudentMap = () => {
  let { town, properties, property, setProperty } = useContext(TownContext)

  const handlePlotClick = function (d) {
    axios.get(`/students/towns/${town.id}/properties/find?name=${d.target.__data__.plotId}`).then(res => setProperty(res.data))
  }

  return ((town && properties) ? <>
      <Map handlePlotClick={handlePlotClick} town={town} plots={properties} />
      {
        property &&
        <Modal
          town={town}
          showModal={true}
          setShowModal={() => setProperty(null)}
          title={`Land Details - ${property.name}`}
          property={property}>
          <div className='flex flex-row gap-x-2 w-full'>
            <div>
              <StudentHeader />
              <PropertySidebar property={property} />
            </div>
            <PropertyDetails town={town} />
          </div>
        </Modal>
      }
    </> : <div className='flex flex-row items-center w-full'><LoadingSpinner /></div>
  )
}

export default StudentMap


