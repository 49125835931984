export const inventoryTypes = {
  MARKET: 'MARKET',
  PURCHASING_POWER: 'PURCHASING_POWER',
}

export const beneficiaryType = {
  CHARACTER: 'CHARACTER',
  AREA: 'AREA',
}

export const wildcardTypes = {
  PROJECT: 'PROJECT',
  LOTTERY: 'LOTTERY',
  DAMAGE: 'DAMAGE',
  INVESTMENT: 'INVESTMENT',
  WILD_MARKET: 'WILD_MARKET',
}

export const DRAWER_WIDTH = 25
export const insuranceCostPerSqFt = 8

export const storageType = {
  WAREHOUSE: 'WAREHOUSE',
  FIELD: 'FIELD',
  FENCE: 'FENCE',
  GARAGE: 'GARAGE',
}

export const storageTypes = [
  { label: 'Field', type: 'FIELD', costPerSqft: 0, plotType: 'rural' },
  { label: 'Fence', type: 'FENCE', costPerSqft: 65, plotType: 'rural' },
  { label: 'Warehouse', type: 'WAREHOUSE', costPerSqft: 65, plotType: 'urban' },
]

export const marketColors = {
  cattle: '#1155cc',
  horse: '#ffff00',
  buffalo: '#d10808',

  wheat: '#ff9900',
  barley: '#b6d7a8',
  canola: '#f5ee00',

  red: '#ff0000',
  blue: '#3d85c6',
  green: '#6aa84f',
  yellow: '#ffff00',
  purple: '#741b47',
  orange: '#ff9900',
}

export const marketStorageType = {
  cattle: storageType.FENCE,
  horse: storageType.FENCE,
  buffalo: storageType.FENCE,

  wheat: storageType.FIELD,
  barley: storageType.FIELD,
  canola: storageType.FIELD,

  red: storageType.WAREHOUSE,
  blue: storageType.WAREHOUSE,
  green: storageType.WAREHOUSE,
  yellow: storageType.WAREHOUSE,
  purple: storageType.WAREHOUSE,
  orange: storageType.WAREHOUSE,
}

export const townPlots = [
  { plotId: 'A-1', x: 70, y: 0, w: 40, h: 40, rotation: 20, type: 'rural' },
  { plotId: 'A-2', x: 15, y: 40, w: 80, h: 40, rotation: 20, type: 'rural' },
  { plotId: 'A-3', x: 120, y: 0, w: 40, h: 90, rotation: 20, type: 'rural' },
  { plotId: 'A-4', x: -10, y: 100, w: 100, h: 60, rotation: 20, type: 'rural' },
  { plotId: 'A-5', x: 100, y: 130, w: 60, h: 60, rotation: 20, type: 'rural' },

  { plotId: 'B-1', x: 495, y: 70, w: 40, h: 75, rotation: 0, type: 'rural' },

  { plotId: 'C-1', x: 170, y: 160, w: 100, h: 60, rotation: 20, type: 'rural' },
  { plotId: 'C-2', x: 195, y: 90, w: 75, h: 60, rotation: 20, type: 'rural' },

  { plotId: 'D-1', x: 305, y: 125, w: 75, h: 60, rotation: 20, type: 'rural' },
  { plotId: 'D-2', x: 280, y: 200, w: 100, h: 60, rotation: 20, type: 'rural' },

  { plotId: 'E-1', x: 425, y: 165, w: 75, h: 60, rotation: 20, type: 'rural' },
  { plotId: 'E-2', x: 400, y: 240, w: 100, h: 60, rotation: 20, type: 'rural' },

  { plotId: 'F-1', x: 640, y: 235, w: 75, h: 60, rotation: 20, type: 'rural' },
  { plotId: 'F-2', x: 595, y: 305, w: 100, h: 60, rotation: 20, type: 'rural' },

  { plotId: 'G-1', x: 725, y: 265, w: 75, h: 60, rotation: 20, type: 'rural' },
  { plotId: 'G-2', x: 700, y: 340, w: 100, h: 60, rotation: 20, type: 'rural' },

  { plotId: 'H-1', x: 220, y: 0, w: 100, h: 40, type: 'rural' },
  { plotId: 'H-2', x: 330, y: 0, w: 60, h: 40, type: 'rural' },
  { plotId: 'H-3', x: 400, y: 0, w: 60, h: 40, type: 'rural' },
  { plotId: 'H-4', x: 490, y: 0, w: 100, h: 40, type: 'rural' },
  { plotId: 'H-5', x: 600, y: 0, w: 100, h: 40, type: 'rural' },
  { plotId: 'H-6', x: 710, y: 0, w: 60, h: 40, type: 'rural' },
  { plotId: 'H-7', x: 780, y: 0, w: 60, h: 40, type: 'rural' },
  { plotId: 'H-8', x: 850, y: 0, w: 60, h: 40, type: 'rural' },

  { plotId: 'I-1', x: 865, y: 50, w: 45, h: 80, type: 'rural' },
  { plotId: 'I-2', x: 865, y: 140, w: 45, h: 40, type: 'rural' },
  { plotId: 'I-3', x: 865, y: 190, w: 45, h: 40, type: 'rural' },
  { plotId: 'I-4', x: 865, y: 240, w: 45, h: 80, type: 'rural' },
  { plotId: 'I-5', x: 865, y: 330, w: 45, h: 80, type: 'rural' },

  { plotId: 'J-1', x: -50, y: 275, w: 70, h: 40, rotation: 20, type: 'urban' },
  { plotId: 'J-2', x: 20, y: 300, w: 70, h: 40, rotation: 20, type: 'urban' },
  { plotId: 'J-3', x: 90, y: 320, w: 40, h: 40, rotation: 20, type: 'urban' },
  { plotId: 'J-4', x: 130, y: 335, w: 40, h: 40, rotation: 20, type: 'urban' },
  { plotId: 'J-5', x: 170, y: 355, w: 70, h: 40, rotation: 20, type: 'urban' },
  { plotId: 'J-6', x: 240, y: 380, w: 70, h: 40, rotation: 20, type: 'urban' },

  { plotId: 'K-1', x: -90, y: 320, w: 35, h: 25, rotation: -25, type: 'urban' },

  { plotId: 'L-1', x: -90, y: 550, w: 40, h: 40, type: 'urban' },
  { plotId: 'L-2', x: -48, y: 550, w: 40, h: 40, type: 'urban' },
  { plotId: 'L-3', x: -6, y: 550, w: 40, h: 40, type: 'urban' },
  { plotId: 'L-4', x: 36, y: 550, w: 40, h: 40, type: 'urban' },
  { plotId: 'L-5', x: 78, y: 550, w: 40, h: 40, type: 'urban' },
  { plotId: 'L-6', x: 120, y: 550, w: 40, h: 40, type: 'urban' },
  { plotId: 'L-7', x: 162, y: 550, w: 40, h: 40, type: 'urban' },
  { plotId: 'L-8', x: 204, y: 550, w: 40, h: 40, type: 'urban' },
  { plotId: 'L-9', x: 246, y: 550, w: 40, h: 40, type: 'urban' },
  { plotId: 'L-10', x: 288, y: 550, w: 40, h: 40, type: 'urban' },


  { plotId: 'M-1', x: 600, y: 475, w: 100, h: 80, rotation: 20, type: 'urban' },
  { plotId: 'M-2', x: 700, y: 510, w: 100, h: 80, rotation: 20, type: 'urban' },
  { plotId: 'M-3', x: 800, y: 545, w: 100, h: 80, rotation: 20, type: 'urban' },
  { plotId: 'M-4', x: 910, y: 570, w: 40, h: 80, rotation: 20, type: 'urban' },

  { plotId: 'N-1', x: 570, y: 565, w: 60, h: 40, rotation: 20, type: 'urban' },
  { plotId: 'N-2', x: 630, y: 585, w: 60, h: 40, rotation: 20, type: 'urban' },
  { plotId: 'N-3', x: 690, y: 605, w: 60, h: 40, rotation: 20, type: 'urban' },
  { plotId: 'N-4', x: 750, y: 625, w: 60, h: 40, rotation: 20, type: 'urban' },
  { plotId: 'N-5', x: 810, y: 645, w: 60, h: 40, rotation: 20, type: 'urban' },
  { plotId: 'N-6', x: 870, y: 665, w: 60, h: 40, rotation: 20, type: 'urban' },

  { plotId: 'O-1', x: -82, y: 650, w: 35, h: 90, type: 'urban' },

  { plotId: 'P-1', x: -75, y: 800, w: 60, h: 60, type: 'urban' },
  { plotId: 'P-2', x: -13, y: 800, w: 60, h: 60, type: 'urban' },
  { plotId: 'P-3', x: 49, y: 800, w: 60, h: 60, type: 'urban' },
  { plotId: 'P-4', x: 111, y: 800, w: 60, h: 60, type: 'urban' },
  { plotId: 'P-5', x: 173, y: 800, w: 60, h: 60, type: 'urban' },
  { plotId: 'P-6', x: 235, y: 800, w: 60, h: 60, type: 'urban' },

  { plotId: 'Q-1', x: -75, y: 870, w: 60, h: 60, type: 'urban' },
  { plotId: 'Q-2', x: -13, y: 870, w: 60, h: 60, type: 'urban' },
  { plotId: 'Q-3', x: 49, y: 870, w: 60, h: 60, type: 'urban' },
  { plotId: 'Q-4', x: 111, y: 870, w: 60, h: 60, type: 'urban' },
  { plotId: 'Q-5', x: 173, y: 870, w: 60, h: 60, type: 'urban' },
  { plotId: 'Q-6', x: 235, y: 870, w: 60, h: 60, type: 'urban' },

  { plotId: 'R-1', x: 660, y: 740, w: 45, h: 45, type: 'urban' },
  { plotId: 'R-2', x: 707, y: 740, w: 45, h: 45, type: 'urban' },
  { plotId: 'R-3', x: 754, y: 740, w: 45, h: 45, type: 'urban' },

  { plotId: 'S-1', x: 660, y: 795, w: 45, h: 45, type: 'urban' },
  { plotId: 'S-2', x: 707, y: 795, w: 45, h: 45, type: 'urban' },
  { plotId: 'S-3', x: 754, y: 795, w: 45, h: 45, type: 'urban' },


  { plotId: 'T-1', x: 825, y: 800, w: 125, h: 80, type: 'urban' },
  { plotId: 'T-2', x: 825, y: 890, w: 125, h: 80, type: 'urban' },


  { plotId: 'U-1', x: -65, y: 990, w: 60, h: 60, type: 'urban' },
  { plotId: 'U-2', x: -3, y: 990, w: 60, h: 60, type: 'urban' },
  { plotId: 'U-3', x: 59, y: 990, w: 60, h: 60, type: 'urban' },
  { plotId: 'U-4', x: 121, y: 990, w: 60, h: 60, type: 'urban' },
  { plotId: 'U-5', x: 183, y: 990, w: 60, h: 60, type: 'urban' },
  { plotId: 'U-6', x: 245, y: 990, w: 60, h: 60, type: 'urban' },
  { plotId: 'U-7', x: 307, y: 990, w: 60, h: 60, type: 'urban' },
  { plotId: 'U-8', x: 369, y: 990, w: 60, h: 60, type: 'urban' },
  { plotId: 'U-9', x: 431, y: 990, w: 60, h: 60, type: 'urban' },
  { plotId: 'U-10', x: 493, y: 990, w: 60, h: 60, type: 'urban' },
  { plotId: 'U-11', x: 555, y: 990, w: 60, h: 60, type: 'urban' },
  { plotId: 'U-12', x: 617, y: 990, w: 60, h: 60, type: 'urban' },
  { plotId: 'U-13', x: 679, y: 990, w: 60, h: 60, type: 'urban' },
  { plotId: 'U-14', x: 741, y: 990, w: 60, h: 60, type: 'urban' },
  { plotId: 'U-15', x: 803, y: 990, w: 60, h: 60, type: 'urban' },
]

export const defaultFeatures = [
  {
    "bio": "Barney McWrinkle was a fearless farmer. He owned and operated an agricultural empire in Braves County.  Bold, strategic & calculated risks allowed Mr. McWrinkle to acquire legendary status. The McWrinkle Mountain Range stands tall overlooking your TOWN map to remind all characters to be confident & bold!",
    "featureId": "mountains-2",
    "featureName": "McWrinkle Mountain Range",
    "name": "Barney McWrinkle",
    "photo": "4c633526-0a01-4457-8f5a-4fe573b43f93",
    "townLogo": "ff30c09e-be9c-4c7c-b1f7-8616b63cc5bc"
  },
  {
    "bio": "Mark Mannerway was a business leader.  He resided and operated multiple businesses in Blue River Falls. Mr. Mannerway managed a huge inventory portfolio which allowed him to reach legendary status.  Mannerway River symbolizes strength & courage to take risks and never settle!",
    "featureId": "river",
    "featureName": "Mannerway River",
    "name": "Mark Mannerway",
    "photo": "5bf5f7ae-7205-451a-8f22-5bd9a4479914",
    "townLogo": "e9e1dad0-f87c-409d-8986-f2d3e558a96b"
  },
  {
    "featureId": "south-stream",
    "featureName": "\nCottingham Creek"
  },
  {
    "bio": "Butterfingers O’Neil lived in Beautician County. Operating both urban & rural businesses propelled Butterfingers to legendary status. O’Neil Park should remind all characters that success is not just reserved for the outspoken but also  for the kind and humble.",
    "featureId": "south-west-park",
    "featureName": "O'Neil Park",
    "name": "Butterfingers O’Neil",
    "photo": "96c14b0f-9174-41cf-985b-df38e5eb71ba",
    "townLogo": "1c75c896-cb5c-4eae-8dd2-5c70fbe68699"
  },
  {
    "bio": "Keith Wayne owned a large agricultural operation in Sun Valley County. Mr. Wayne achieved legendary status through his outstanding work ethic.  The vastness & beauty of Wayne Park is present to remind all characters that hard work pays off!",
    "featureId": "south-east-park",
    "featureName": "Wayne Park",
    "name": "Keith Wayne",
    "photo": "afc70980-fb19-4ef0-89e0-61880042e17b",
    "townLogo": "7cfed0c8-ce37-4643-bd38-7997ee7d8c17"
  },
  {
    "bio": "Rocky Gilberts owned a family farm in Banana Land County. 100% of his effort and focus was on his agricultural operations resulting in legendary status.  Gilberts Grove exemplifies that family values & quiet confidence can lead to greatness.",
    "featureId": "central-park",
    "featureName": "Gilberts Grove",
    "name": "Rocky Gilberts",
    "photo": "2b78372b-96b4-4621-b3f4-009567a56a4c",
    "townLogo": "89a2dbd3-44c3-446e-bd31-1c0059fe673d"
  },
  {
    "bio": "Puglas Head resided in Sun Valley County. He owned both rural & urban land reflecting his relentless effort to expand his empire. He brilliantly managed inventory & agricultural assets. Head Lake symbolizes an obsession to excel.",
    "featureId": "north-lake",
    "featureName": "Head Lake",
    "name": "Puglas Head",
    "photo": "2569869b-7179-426d-988d-ab4ddd3cfcc2",
    "townLogo": "84e8aeff-c616-4c00-800d-016a1c50a67b"
  },
  {
    "bio": "Jade Woodrock lived in the economic powerhouse Blue River Falls. She built an inventory empire that catapulted her status to legendary.  Woodrock Lake reflects a feisty attitude to succeed.",
    "featureId": "south-lake",
    "featureName": "Woodrock Lake",
    "name": "Jade Woodrock",
    "photo": "73e786f4-28c9-4117-98ee-8ac86a10794c",
    "townLogo": "1adcd86d-547a-4517-bce1-fdbf34a44857"
  },
  {
    "bio": "John Toe lived in the majestic community of Spring Haven.  He reached legendary status from operating multiple businesses and moving inventory & agricultural assets.  Toe Gardens represents a desire to excel and willingness to take risks.",
    "featureId": "north-park",
    "featureName": "Toe Gardens",
    "name": "John Toe",
    "photo": "48b97699-0803-45cf-a42c-c001606bca85",
    "townLogo": "c6c27017-a846-47e8-a23b-9670b1ea4ca0"
  },
  {
    "bio": "Nevaeh Rose lived in the lovely town of Mountain Springs. Operating multiple businesses and managing a massive inventory portfolio gave Ms. Rose legendary status.  Rose Forest symbolizes a fierce and competitive spirit driven to succeed.",
    "featureId": "north-west-park",
    "featureName": "Rose Forest",
    "name": "Nevaeh Rose",
    "photo": "fbd87da5-d875-412f-83ac-8e022358a09a",
    "townLogo": "59513e59-4e57-458c-b7a1-1dbcc700d3ea"
  }
]
