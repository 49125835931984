import React, { useContext } from "react";
import BuyLandItem from "./buyLandItem";
import { TownContext } from "../../shared/townContext";

const BuyLand = ({ town }) => {
  let { property } = useContext(TownContext)

  return (<>
    {property.land_type === 'county' && <>
      <BuyLandItem town={town} type={'fences'} maxArea={property.available_area} />
      <BuyLandItem town={town} type={'fields'} maxArea={property.available_area} />
    </>}
    {property.land_type === 'town' && <>
      <BuyLandItem town={town} type={'warehouses'} maxArea={property.available_area} />
    </>}
  </>)
}

export default BuyLand
