import React, { useState } from "react";
import BuyLand from "./buyLand";
import SellLand from "./sellLand";

const ManageLand = ({ town }) => {
  let [action, setAction] = useState('buy')

  return (<>
    <div className='border-b-2 border-gray-300 py-3'>
      <div className='flex flex-row items-center mt-2 mb-4'>
        <h3 className='text-yellow-600'>Manage Storage</h3>
        <button
          className={`ml-6 py-1 font-semibold border-yellow-500 ${action === 'buy' && 'border-b-2'}`}
          onClick={() => setAction('buy')}
        >Buy
        </button>
        <button
          className={`py-1 ml-2 font-semibold border-yellow-500 ${action === 'sell' && 'border-b-2'}`}
          onClick={() => setAction('sell')}
        >Sell
        </button>
      </div>
      {action === 'buy' && <BuyLand town={town} />}
      {action === 'sell' && <SellLand town={town} />}
    </div>
  </>)
}

export default ManageLand
