import React, { useContext, useEffect, useState } from 'react'
import { formatPrice } from "../shared/utils";
import { TownContext } from "./shared/townContext";
import axios from "axios";

const Transactions = () => {
  const { town } = useContext(TownContext)
  let [transactions, setTransactions] = useState()
  let [currentBalance, setCurrentBalance] = useState()

  useEffect(() => {
    axios.get(`/students/towns/${town.id}/transactions.json`).then(res => {
      setTransactions(res.data.transactions)
      setCurrentBalance(res.data.current_balance)
    })
  }, [])

  const TransactionRow = ({ transaction }) => {
    return <tr className="border-b-2 border-gray-200">
      <td className="py-3">
        {transaction.date}
      </td>
      <td>
        {transaction.description}
      </td>
      <td className="text-right <%= transaction.amount >= 0 ? 'text-green-500' : 'text-red-500' %>">
        {formatPrice(transaction.amount)}
      </td>
    </tr>
  }

  return <div className="shadow-md my-4 mx-auto p-6">
    <table className="my-3 w-full text-left">
      <thead>
      <tr className="border-b-2 border-gray-200">
        <th className="py-3">Date</th>
        <th>Description</th>
        <th className="text-right">Amount ($)</th>
      </tr>
      </thead>
      <tbody>
      {transactions && transactions.map(transaction => <TransactionRow key={transaction.id} transaction={transaction} />)}
      <tr>
        <td className="font-bold py-2">Current Balance</td>
        <td />
        <td
          className="font-bold text-right py-2 <%= student_account.current_balance >= 0 ? 'text-black' : 'text-red-500' %>">
          {currentBalance && formatPrice(currentBalance)}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
}

export default Transactions
