import { ResponsiveLine } from "@nivo/line";
import React, { useEffect, useState } from "react";
import axios from "axios";

const PriceChart = ({ type, pricingRoute }) => {
  let [chartData, setChartData] = useState()

  const chartColor = () => {
    // livestock
    if (type === 'cattle') {
      return "hsl(346, 70%, 50%)"
    } else if (type === 'horse') {
      return "hsl(73, 70%, 50%)"
    } else if (type === 'buffalo') {
      return "hsl(34, 70%, 50%)"
    }

    // crops
    if (type === 'barley') {
      return "hsl(346, 70%, 50%)"
    } else if (type === 'wheat') {
      return "hsl(73, 70%, 50%)"
    } else if (type === 'canola') {
      return "hsl(34, 70%, 50%)"
    }

    // goods
    if (type === 'red') {
      return "hsl(346, 70%, 50%)"
    } else if (type === 'blue') {
      return "hsl(73, 70%, 50%)"
    } else if (type === 'green') {
      return "hsl(34, 70%, 50%)"
    } else if (type === 'yellow') {
      return "hsl(34, 70%, 50%)"
    } else if (type === 'purple') {
      return "hsl(34, 70%, 50%)"
    } else if (type === 'orange') {
      return "hsl(34, 70%, 50%)"
    }
  }

  useEffect(() => {
    axios.get(pricingRoute).then(res => {
      setChartData([
        {
          id: type,
          color: chartColor,
          data: res.data.map((y, x) => {
            return { x: x + 1, y: y }
          })
        }])
    })
  }, [])

  return <div className='h-48 w-full'>
    {chartData && <ResponsiveLine
      data={chartData}
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      xScale={{ type: 'point' }}
      yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'day',
        legendOffset: 36,
        legendPosition: 'middle'
      }}
      axisLeft={{
        orient: 'left',
        legend: 'price',
        legendOffset: -40,
        legendPosition: 'middle'
      }}
      enableGridX={false}
      pointSize={10}
      pointBorderWidth={2}
      pointLabelYOffset={-12}
      useMesh={true}
    />}
  </div>
}

export default PriceChart
