import React from 'react'

// Button for buying/selling items from the market. Will be disabled if the market is closed.
const MarketButton = ({ town, property, children, onClick, className, forceDisabled=false }) => {
  let defaultClassName = `w-30 rounded-md px-3 py-1 text-white`;

  let ownedByMe = property === undefined || property.owned_by_me

  return <>
    <button
      className={`${className ?? defaultClassName} ${(town.is_market_open && ownedByMe) ? 'bg-yellow-600' : 'bg-gray-400'}`}
      title={town.is_market_open ? null : 'Market is closed'}
      onClick={onClick}
      disabled={!town.is_market_open || forceDisabled}
    >{children}
    </button>
  </>

}

export default MarketButton
