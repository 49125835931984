import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { formatPrice } from "../../shared/utils";
import Notifications from "./notifications";

export const TownContext = createContext({
  town: null,
  townLogo: null,
  student: null,
  studentLogo: null,
  properties: null,
  property: null,
  setProperty: null,
  error: null,
  setError: null
})

const Town = ({ selectedProperty, children }) => {
  let [student, setStudent] = useState()
  let [studentLogo, setStudentLogo] = useState()
  let [town, setTown] = useState()
  let [townLogo, setTownLogo] = useState()
  let [properties, setProperties] = useState()
  let [property, setProperty] = useState(selectedProperty)
  let [error, setError] = useState()
  let [notice, setNotice] = useState()
  let { townId } = useParams()

  useEffect(() => {
    if (student) {
      axios.get(`/students/students/${student.id}/logo`).then(res => setStudentLogo(res.data.student_image))
    }
  }, [student])

  useEffect(() => {
    if (town) {
      axios.get(`/students/towns/${town.id}/logo`).then(res => setTownLogo(res.data.logo))
    }
  }, [town])

  useEffect(() => {
    axios.get(`/students/towns/${townId}.json`).then(res => setTown(res.data))
  }, [])

  useEffect(() => {
    town && axios.get(`/students/towns/${town.id}/properties.json`).then(res => setProperties(res.data))
  }, [town])

  useEffect(() => {
    town && axios.get(`/students/me`, { params: { town_id: town.id } }).then(res => setStudent(res.data))
  }, [town, property])

  return (
    <TownContext.Provider value={{
      town: town,
      townLogo: townLogo,
      student: student,
      studentLogo: studentLogo,
      properties: properties,
      property: property,
      setProperty: setProperty,
      error: error,
      setError: setError,
      notice: notice,
      setNotice: setNotice
    }}>
      {town && student && <div className="flex flex-row justify-between items-center px-9 py-2 bg-blue-900 text-white">
        <div className="flex flex-row gap-x-2">
          <h3 className="text-lg uppercase">
            {town.name}
          </h3>
          <div
            className="bg-yellow-700 rounded-lg px-2 text-white uppercase text-sm font-semibold items-center justify-center flex">
            <span>Step {town.step}</span></div>
        </div>
        <div className="flex flex-row gap-x-9 ml-20 items-center">
          <div className="font-bold text-yellow-600">
            Balance: {formatPrice(student.current_balance)}
          </div>
          <div className="font-bold text-yellow-600">
            Net worth: {formatPrice(student.net_worth)}
          </div>
          <div>
            <Link to={`/students/towns/${townId}/students`}>Dashboard</Link>
          </div>
          <div>
            <Link to={`/students/towns/${townId}`}>Map</Link>
          </div>
          <div className='flex flex-row gap-x-2 items-center'>
            <Link to={`/students/towns/${townId}/markets`}>Market</Link>
            <div
              className={`${town.is_market_open ? 'bg-green-700' : 'bg-red-700'} rounded-lg p-1 text-white uppercase text-xs items-center justify-center flex`}>
              <span>{town.is_market_open ? 'OPEN' : 'CLOSED'}</span></div>
          </div>
          <div>
            <Link to={`/students/towns/${townId}/leaderboards`}>Leaderboard</Link>
          </div>
          <div>
            <a href='/students/sign_out' data-method='delete'>Logout</a>
          </div>
          {studentLogo && <img alt='student logo' src={studentLogo} className='rounded-full object-cover object-center h-16' />}
        </div>
      </div>}

      <div className="text-gray-800 p-4">
        {children}
      </div>
      {(error || notice) && <Notifications />}
    </TownContext.Provider>
  )
}

export default Town
