import React, { useEffect, useState } from "react";
import axios from "axios";
import TownLeader from "./townLeader";

const TownsLeaderboard = ({ town, baseRoute }) => {
  const [leaders, setLeaders] = useState()

  useEffect(() => {
    axios.get(`${baseRoute}/towns/${town.id}/leaderboards/towns`).then(res => setLeaders(res.data))
  }, [])

  return <div className='flex flex-col w-1/3 border-8 rounded-xl border-blue-900'>
    <h2 className='w-full text-center font-semibold text-xl py-4'>Town Leaderboard</h2>

    <div className='flex flex-col gap-y-8 mt-4'>
      {leaders && leaders.map((town, index) => <TownLeader key={town.id} town={town} rank={index + 1} baseRoute={baseRoute} />)}
    < /div>
  </div>
}

export default TownsLeaderboard
