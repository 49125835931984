import React, { useContext } from "react";
import 'rc-slider/assets/index.css';
import LandItem from "./landItem";
import { TownContext } from "../../shared/townContext";

const SellLandItem = ({ town, type, maxArea }) => {
  let { property } = useContext(TownContext)

  return <LandItem type={type} maxArea={maxArea} action='sell'
                   route={`/students/towns/${town.id}/properties/${property.id}/${type}/sell`} />
}

export default SellLandItem
