export const getFeatureColor = (feature, i, array, hover) => {
  switch (feature.type) {
    case 'stream':
    case 'lake':
    case 'river':
      return hover ? '#8ccdff' : '#aadaff'

    case 'foothills':
    case 'park':
      return hover ? '#c9e8c9' : '#ceeece'

    case 'foothills-0':
      return hover ? '#c9e8c9' : '#c2e0c2'

    case 'foothills-1':
      return hover ? '#bedbbe' : '#b6d6b6'

    case 'mountains-1':
      return hover ? '#c0c0c0' : '#c2c2c2'

    case 'mountains-2':
      return hover ? '#e0e0e0' : '#e2e2e2'

    case 'mountains-3':
      return hover ? '#d0d0d0' : '#d2d2d2'

    case 'sky':
      return '#e3f3ff'

    case 'plains-0':
      return '#fffbbd'

    default:
      return '#ff0000'
  }
}

export const townFeatures = [
  {
    id: 'north-lake',
    labelX: 680,
    labelY: 132,
    labelRotation: 0,
    type: 'lake',
    points:
      ` 820.30078125,69.54886627197266
        775.18798828125,65.03758239746094
        739.0977783203125,62.781944274902344
        710.9022827148438,62.781944274902344
        684.96240234375,65.03758239746094
        636.4661865234375,67.29322052001953
        584.5864868164062,69.54886627197266
        567.669189453125,71.80450439453125
        560.9022827148438,89.84961700439453
        554.1353759765625,107.89472961425781
        554.1353759765625,123.6842041015625
        558.6466064453125,142.8571319580078
        568.7969970703125,153.00750732421875
        594.7368774414062,180.07518005371094
        615.03759765625,194.73683166503906
        637.593994140625,204.88720703125
        672.556396484375,207.14285278320312
        715.41357421875,209.3984832763672
        759.3984985351562,218.42105102539062
        788.7218017578125,227.443603515625
        820.30078125,236.46615600585938
        837.2180786132812,216.1654052734375
        839.4736938476562,189.0977325439453
        839.4736938476562,153.00750732421875
        838.3458862304688,113.53382873535156
        836.0902709960938,71.80450439453125`
  },
  {
    id: 'south-lake',
    labelX: 218,
    labelY: 689,
    labelRotation: 0,
    type: 'lake',
    points:
      ` 139.09774780273438,616.1654052734375
        101.87969970703125,617.293212890625
        60.1503791809082,618.4210815429688
        33.08271026611328,621.8045043945312
        6.015039920806885,629.6992797851562
        -18.7969913482666,639.849609375
        -30.075185775756836,663.5338134765625
        -31.203006744384766,690.6015014648438
        -31.203006744384766,714.2857055664062
        -26.691728591918945,735.7142944335938
        -10.902254104614258,756.0150146484375
        11.65413761138916,768.4210815429688
        40.977447509765625,774.0601806640625
        82.70677185058594,778.5714111328125
        123.30827331542969,778.5714111328125
        166.16542053222656,778.5714111328125
        220.30076599121094,776.3157958984375
        263.15789794921875,776.3157958984375
        282.3308410644531,776.3157958984375
        321.8045349121094,780.8270874023438
        338.7218017578125,823.6842041015625
        345.4887390136719,856.3909912109375
        344.36090087890625,884.5864868164062
        355.63909912109375,911.6541137695312
        399.62408447265625,913.9097900390625
        454.8872375488281,916.1654052734375
        492.10528564453125,911.6541137695312
        515.7894897460938,900.3759765625
        529.3233032226562,878.9473876953125
        538.3458862304688,847.368408203125
        538.3458862304688,815.7894897460938
        519.1729736328125,769.5488891601562
        488.7218017578125,724.4360961914062
        470.67669677734375,699.6240844726562
        454.8872375488281,677.0676879882812
        427.8195495605469,650
        405.26318359375,631.9548950195312
        363.5338439941406,615.03759765625
        334.2105407714844,609.3984985351562
        299.2481384277344,609.3984985351562
        267.669189453125,610.5263061523438
        227.0676727294922,609.3984985351562
        168.42105102539062,613.9097900390625`
  },
  // {
  //   type: 'stream',
  //   points:
  //     ` 503.68634033203125,351.64105224609375
  //       510.4440612792969,335.873046875
  //       514.94921875,323.48388671875
  //       523.95947265625,308.8421630859375
  //       537.4749145507812,289.6952819824219
  //       546.4852294921875,270.54840087890625
  //       555.4955444335938,248.0226593017578
  //       562.2532348632812,226.62319946289062
  //       564.5057983398438,209.72889709472656
  //       564.5057983398438,192.8345947265625
  //       569.010986328125,175.94029235839844
  //       576.8949584960938,157.91970825195312
  //       590.410400390625,162.4248504638672
  //       591.5366821289062,182.69801330566406
  //       585.9052734375,198.46603393554688
  //       579.1475830078125,220.9917755126953
  //       574.6423950195312,241.2649383544922
  //       566.7584228515625,257.032958984375
  //       558.8743896484375,271.6746826171875
  //       549.8640747070312,289.6952819824219
  //       537.4749145507812,308.8421630859375
  //       526.2120971679688,327.989013671875
  //       517.2017822265625,342.6307678222656`
  // },
  {
    id: 'south-stream',
    type: 'stream',
    labelX: 346,
    labelY: 453,
    labelRotation: 280,
    points:
      ` 307.71240234375,358.3987731933594
        324.6067199707031,368.5353698730469
        334.7432861328125,378.67193603515625
        338.1221618652344,393.31365966796875
        340.3747253417969,412.4605407714844
        340.3747253417969,430.48114013671875
        336.9958801269531,446.2491455078125
        336.9958801269531,462.0171813964844
        332.49072265625,476.6589050292969
        327.9855651855469,495.8057861328125
        334.7432861328125,511.57379150390625
        342.6272888183594,526.2155151367188
        347.1324462890625,541.9835205078125
        343.75360107421875,556.625244140625
        336.9958801269531,572.393310546875
        334.7432861328125,584.782470703125
        331.36444091796875,598.2979125976562
        332.49072265625,612.9396362304688
        344.8798828125,626.455078125
        357.26904296875,628.7076416015625
        360.64788818359375,611.8133544921875
        364.0267639160156,598.2979125976562
        362.9004821777344,585.9087524414062
        357.26904296875,578.0247192382812
        357.26904296875,567.8881225585938
        361.774169921875,558.8778686523438
        368.5318908691406,547.614990234375
        370.78448486328125,532.9732666015625
        364.0267639160156,519.4578247070312
        355.0164489746094,509.32122802734375
        348.25872802734375,503.6897888183594
        344.8798828125,493.5531921386719
        347.1324462890625,478.9114685058594
        348.25872802734375,466.5223083496094
        351.6376037597656,450.7543029785156
        355.0164489746094,439.4914245605469
        357.26904296875,428.22857666015625
        359.5216064453125,418.09197998046875
        360.64788818359375,405.70281982421875
        357.26904296875,392.1873779296875
        352.7638854980469,373.0404968261719`
  },
  {
    id: 'river',
    type: 'river',
    labelX: 329,
    labelY: 321,
    labelRotation: 20,
    points:
      ` -96.62459564208984,101.60535430908203
        -87.61429595947266,117.37337493896484
        -69.59370422363281,130.88882446289062
        -43.689109802246094,144.40426635742188
        -18.910797119140625,151.1619873046875
        6.993802547454834,162.4248504638672
        31.772113800048828,171.43515014648438
        50.91899108886719,178.19287109375
        77.94987487792969,188.32945251464844
        107.23333740234375,197.33975219726562
        130.88536071777344,206.35003662109375
        165.80026245117188,216.4866180419922
        199.5888671875,227.74949645996094
        242.3877716064453,240.13864135742188
        300.9546813964844,263.7906799316406
        370.78448486328125,287.44268798828125
        420.3410949707031,303.2107238769531
        462.0137023925781,317.8524475097656
        487.9183044433594,329.1153259277344
        512.6966552734375,343.7570495605469
        548.73779296875,353.89361572265625
        572.3898315429688,359.5250549316406
        600.5469970703125,365.156494140625
        643.3458862304688,384.3033752441406
        674.8819580078125,394.43994140625
        706.41796875,405.70281982421875
        742.4591674804688,415.83941650390625
        778.5003662109375,423.7234191894531
        810.036376953125,429.3548583984375
        857.3403930664062,434.9862976074219
        884.371337890625,434.9862976074219
        921.5387573242188,437.2388610839844
        942.938232421875,433.8599853515625
        973.3479614257812,423.7234191894531
        995.8737182617188,415.83941650390625
        994.7474365234375,573.5195922851562
        927.1702270507812,554.3726806640625
        884.371337890625,538.6046752929688
        848.3301391601562,529.5943603515625
        819.0466918945312,520.5841064453125
        771.7426147460938,501.4372253417969
        715.4282836914062,485.669189453125
        665.8716430664062,469.90118408203125
        642.2196044921875,457.51202392578125
        609.5573120117188,448.5017395019531
        584.7789916992188,445.12286376953125
        554.3692626953125,441.7440185546875
        507.065185546875,419.21826171875
        481.16058349609375,407.95538330078125
        451.87713623046875,392.1873779296875
        420.3410949707031,384.3033752441406
        395.5627746582031,380.92449951171875
        352.7638854980469,377.545654296875
        326.8592834472656,373.0404968261719
        288.5655212402344,365.156494140625
        246.89291381835938,357.2724914550781
        215.3568878173828,344.8833312988281
        190.5785675048828,336.99932861328125
        161.29510498046875,324.61016845703125
        125.2539291381836,311.0947265625
        95.97046661376953,297.57928466796875
        71.19215393066406,286.31640625
        44.16127014160156,278.4324035644531
        19.382959365844727,272.80096435546875
        -7.647927284240723,260.41180419921875
        -27.921091079711914,253.6540985107422
        -50.44683074951172,251.40151977539062
        -61.70969772338867,266.0432434082031
        -68.46742248535156,281.8112487792969
        -83.1091537475586,299.83184814453125
        -95.49830627441406,307.7158508300781`
  },
  {
    id: 'north-park',
    type: 'park',
    labelX: 384,
    labelY: 84,
    labelRotation: 0,
    points:
      ` 237.8826141357422,54.301307678222656
        253.650634765625,53.17502212524414
        271.6712341308594,54.301307678222656
        294.19696044921875,55.42759323120117
        314.4701232910156,56.55388259887695
        346.00616455078125,56.55388259887695
        370.78448486328125,56.55388259887695
        403.4468078613281,55.42759323120117
        425.9725341796875,54.301307678222656
        445.1194152832031,56.55388259887695
        458.6348571777344,66.69046020507812
        467.6451416015625,83.58477020263672
        474.4028625488281,107.2367935180664
        478.90802001953125,130.88882446289062
        467.6451416015625,138.7728271484375
        449.62457275390625,133.14138793945312
        427.09881591796875,124.13109588623047
        402.32049560546875,119.62594604492188
        368.5318908691406,110.61565399169922
        349.385009765625,103.8579330444336
        323.4804382324219,97.10021209716797
        300.9546813964844,90.34249114990234
        284.0603942871094,81.33219146728516
        269.41864013671875,75.70075988769531
        245.76663208007812,67.8167495727539`
  },
  {
    id: 'south-west-park',
    labelX: 57,
    labelY: 446,
    labelRotation: 0,
    type: 'park',
    points:
      ` -100.00167083740234,370.7424011230469
        -80.74840545654297,363.0411071777344
        -65.34578704833984,357.2651062011719
        -42.24186706542969,345.7131652832031
        -27.80191421508789,338.9745178222656
        -7.5859832763671875,338.0118408203125
        15.517938613891602,343.787841796875
        39.58452224731445,351.4891357421875
        65.5764389038086,359.1904296875
        96.38166046142578,368.81707763671875
        137.77618408203125,381.3316955566406
        175.320068359375,398.6596374511719
        206.1252899169922,409.2489318847656
        244.63182067871094,424.65155029296875
        275.4370422363281,436.2035217285156
        294.6903381347656,443.9048156738281
        309.1302795410156,459.30743408203125
        314.90625,483.3740234375
        312.0182800292969,502.6272888183594
        308.1676025390625,522.8432006835938
        285.0636901855469,526.69384765625
        258.1091003417969,526.69384765625
        230.19187927246094,525.731201171875
        196.4986572265625,526.69384765625
        161.8427734375,528.6192016601562
        123.33624267578125,530.5445556640625
        98.30699157714844,525.731201171875
        54.98713684082031,526.69384765625
        17.443265914916992,526.69384765625
        -3.7353296279907227,528.6192016601562
        -31.652568817138672,528.6192016601562
        -63.42045974731445,529.5818481445312
        -91.33769989013672,528.6192016601562
        -100.00167083740234,493.96331787109375
        -98.07633972167969,472.78472900390625
        -98.07633972167969,431.39019775390625
        -99.03900909423828,395.7716369628906`
  },
  {
    id: 'south-east-park',
    labelX: 582,
    labelY: 680,
    labelRotation: 0,
    type: 'park',
    points:
      ` 373.62872314453125,392.24188232421875
        395.7699890136719,393.2045593261719
        414.0605773925781,396.092529296875
        437.1645202636719,400.9058532714844
        460.2684326171875,412.45782470703125
        481.447021484375,431.7110900878906
        503.5882873535156,443.2630310058594
        536.31884765625,455.7776794433594
        550.7587890625,458.6656494140625
        571.9373779296875,463.4789733886719
        587.3400268554688,466.3669738769531
        588.3026733398438,485.6202392578125
        575.7880249023438,498.1348571777344
        572.9000244140625,516.4254760742188
        570.9747314453125,529.9027709960938
        566.161376953125,550.11865234375
        562.3107299804688,564.5586547851562
        553.6467895507812,583.8118896484375
        553.6467895507812,599.2145385742188
        576.7506713867188,612.6918334960938
        601.7799682617188,618.4677734375
        634.510498046875,633.8704223632812
        660.50244140625,642.5343627929688
        681.6810302734375,651.1983642578125
        716.3369140625,660.824951171875
        735.5901489257812,667.5635986328125
        765.4327392578125,676.2276000976562
        785.648681640625,684.8915405273438
        799.1259155273438,692.5928955078125
        808.7525634765625,704.1448364257812
        803.9392700195312,714.734130859375
        795.2752685546875,724.3607788085938
        778.9100341796875,727.2487182617188
        764.4700927734375,728.21142578125
        744.254150390625,728.21142578125
        725.9635009765625,726.2860717773438
        705.7476196289062,726.2860717773438
        689.38232421875,728.21142578125
        673.9796752929688,728.21142578125
        658.5770874023438,729.174072265625
        650.8757934570312,733.0247192382812
        650.8757934570312,753.2406616210938
        650.8757934570312,767.6806030273438
        651.8384399414062,789.8218994140625
        649.9131469726562,805.2244873046875
        647.98779296875,822.5524291992188
        647.025146484375,845.6563720703125
        662.427734375,853.357666015625
        683.6063232421875,855.282958984375
        708.6355590820312,858.1709594726562
        737.5155029296875,862.0216064453125
        759.65673828125,861.0589599609375
        777.9473266601562,864.9096069335938
        779.8726806640625,874.5362548828125
        780.8353271484375,891.8641967773438
        778.9100341796875,906.3041381835938
        778.9100341796875,922.66943359375
        777.9473266601562,935.1840209960938
        777.9473266601562,947.6986694335938
        776.0220336914062,962.1386108398438
        753.8807373046875,965.026611328125
        726.9262084960938,966.9519653320312
        699.0089721679688,966.9519653320312
        681.6810302734375,966.9519653320312
        649.9131469726562,967.9146118164062
        617.1825561523438,966.9519653320312
        583.4893188476562,967.9146118164062
        547.8707885742188,966.9519653320312
        504.5509338378906,970.8026123046875
        467.9697265625,970.8026123046875
        418.8739013671875,972.7279052734375
        366.89007568359375,972.7279052734375
        326.4582214355469,971.7652587890625
        309.1302795410156,956.3626098632812
        305.2796325683594,940.9600219726562
        307.2049560546875,928.4453735351562
        327.4208679199219,924.5947265625
        351.4874572753906,924.5947265625
        386.1433410644531,929.4080810546875
        419.8365783691406,930.3707275390625
        451.6044616699219,929.4080810546875
        481.447021484375,926.5200805664062
        513.2149047851562,918.8187866210938
        531.5054931640625,900.5281372070312
        542.0948486328125,875.4989013671875
        550.7587890625,849.5070190429688
        554.6094360351562,826.403076171875
        544.9827880859375,800.4111938476562
        527.6548461914062,768.6432495117188
        508.401611328125,739.7633666992188
        490.1109924316406,708.9581298828125
        477.59637451171875,690.6675415039062
        462.1937561035156,666.6009521484375
        441.9778137207031,651.1983642578125
        422.72454833984375,632.90771484375
        404.4339599609375,615.5797729492188
        392.8819885253906,604.02783203125
        385.1806945800781,596.3265380859375
        375.5540466308594,580.9238891601562
        376.5167236328125,566.4839477539062
        382.2926940917969,555.8946533203125
        391.9193420410156,541.4547119140625
        389.9939880371094,526.0521240234375
        381.33001708984375,509.68682861328125
        370.74072265625,494.2842102050781
        362.0767517089844,475.99359130859375
        364.0020751953125,453.85235595703125
        366.89007568359375,431.7110900878906
        371.7033996582031,410.5325012207031`
  },
  {
    id: 'central-park',
    labelX: 563,
    labelY: 241,
    labelRotation: -20,
    type: 'park',
    points:
      ` 541.9794921875,166.825927734375
        562.2525634765625,173.5836181640625
        575.7678833007812,180.34129333496094
        585.9044189453125,191.60409545898438
        596.0409545898438,203.9931640625
        611.808837890625,214.1296844482422
        617.4402465820312,228.77133178710938
        615.1876831054688,243.4129638671875
        607.3037109375,260.3071594238281
        601.67236328125,273.822509765625
        594.9146728515625,295.2218322753906
        588.156982421875,309.86346435546875
        580.2730102539062,323.37884521484375
        566.7576904296875,333.5153503417969
        546.484619140625,332.3890686035156
        522.832763671875,324.505126953125
        502.5597229003906,318.87371826171875
        505.9385681152344,299.7269592285156
        511.5699462890625,285.0853271484375
        521.7064819335938,270.4436950683594
        526.2116088867188,254.67576599121094
        519.4539184570312,243.4129638671875
        512.6962280273438,236.65528869628906
        510.44366455078125,219.76109313964844
        513.822509765625,202.86688232421875
        519.4539184570312,189.35153198242188
        526.2116088867188,175.83616638183594`
  },
  {
    id: 'north-west-park',
    labelX: -50,
    labelY: 43,
    labelRotation: -20,
    type: 'park',
    points:
      ` -90.75342559814453,-6.9178080558776855
        -89.72602844238281,23.904109954833984
        -91.78082275390625,54.72602844238281
        -91.78082275390625,75.27397918701172
        -88.6986312866211,91.71233367919922
        -81.50685119628906,100.95890808105469
        -68.15068054199219,111.23287963867188
        -54.794517517089844,118.4246597290039
        -38.356163024902344,126.64384460449219
        -24.999998092651367,124.58904266357422
        -18.835613250732422,97.87671661376953
        -11.643832206726074,81.43836212158203
        -1.369859218597412,58.83562088012695
        4.794524669647217,40.34246826171875
        9.931510925292969,23.904109954833984
        18.15069007873535,9.520548820495605
        35.6164436340332,7.46575403213501
        47.94520950317383,-2.8082189559936523
        24.315073013305664,-6.9178080558776855`
  },
  {
    id: 'sky',
    labelX: -500,
    labelY: 0,
    labelRotation: 0,
    type: 'sky',
    points:
      ` -300, -17
        -300, -300,
        1500, -300
        1500, -17`
  },
  {
    type: 'mountains-1',
    labelX: -500, //hidden
    labelY: 0,
    labelRotation: 0,
    id: 'mountains-1',
    points:
      ` 57.86933898925781,-119.71025848388672
        70.08734130859375,-150.25526428222656
        87.54163360595703,-104.87410736083984
        92.77792358398438,-124.0738296508789
        98.88692474365234,-115.34668731689453
        99.7596435546875,-104.87410736083984
        141.64993286132812,-163.3459930419922
        148.63165283203125,-176.43670654296875
        155.61337280273438,-193.8909912109375
        169.57681274414062,-186.03656005859375
        178.303955078125,-173.8185577392578
        192.2673797607422,-157.2369842529297
        213.21253967285156,-142.4008331298828
        234.15768432617188,-171.20042419433594
        248.12112426757812,-186.03656005859375
        264.70269775390625,-196.50914001464844
        276.9206848144531,-181.67298889160156
        286.52056884765625,-171.20042419433594
        299.61126708984375,-155.49156188964844
        309.2111511230469,-141.5281219482422
        332.7744445800781,-162.4732666015625
        361.5740051269531,-178.18212890625
        372.0466003417969,-180.80027770996094
        380.77374267578125,-173.8185577392578
        388.628173828125,-166.83685302734375
        393.86444091796875,-159.85513305664062
        406.9551696777344,-150.25526428222656
        426.1549072265625,-155.49156188964844
        431.39117431640625,-162.4732666015625
        441.86376953125,-172.07313537597656
        454.0817565917969,-182.54571533203125
        464.5543212890625,-176.43670654296875
        469.7906188964844,-165.09141540527344
        478.51776123046875,-176.43670654296875
        490.73577880859375,-165.96412658691406
        500.33563232421875,-155.49156188964844
        511.680908203125,-148.5098419189453
        523.89892578125,-139.78269958496094
        579.752685546875,-134.54640197753906
        604.1886596679688,-134.54640197753906
        637.351806640625,-149.38255310058594
        646.0789794921875,-162.4732666015625
        658.2969970703125,-180.80027770996094
        667.0241088867188,-171.20042419433594
        674.005859375,-164.2187042236328
        684.4783935546875,-157.2369842529297
        769.1317138671875,-150.25526428222656
        787.458740234375,-158.982421875
        799.6767578125,-164.2187042236328
        811.0220336914062,-165.09141540527344
        822.3673095703125,-165.09141540527344
        834.5853271484375,-162.4732666015625
        846.8033447265625,-157.2369842529297
        914.0023193359375,-166.83685302734375
        920.111328125,-175.56399536132812
        927.0930786132812,-179.9275665283203
        941.92919921875,-171.20042419433594
        953.2744750976562,-72.58367156982422
        538.7350463867188,-50.76580810546875
        56.1239128112793,-53.383949279785156`
  },

  {
    type: 'mountains-2',
    labelX: 262,
    labelY: -135,
    labelRotation: 0,
    id: 'mountains-2',
    points:
      `-85.25585174560547,-122.32839965820312
      -78.27413177490234,-161.60055541992188
      -74.78327941894531,-174.6912841796875
      -62.56527328491211,-168.582275390625
      -57.328983306884766,-186.03656005859375
      -46.85641098022461,-198.25457763671875
      -31.147546768188477,-175.56399536132812
      -15.438684463500977,-158.982421875
      -7.584253311157227,-178.18212890625
      2.8883216381073,-190.400146484375
      15.979040145874023,-165.96412658691406
      21.215328216552734,-155.49156188964844
      28.197044372558594,-141.5281219482422
      40.41504669189453,-130.18283081054688
      44.778621673583984,-149.38255310058594
      53.505767822265625,-167.70956420898438
      68.34191131591797,-142.4008331298828
      79.68720245361328,-124.0738296508789
      92.77792358398438,-110.11039733886719
      110.23221588134766,-150.25526428222656
      126.81378936767578,-170.32769775390625
      141.64993286132812,-152.00070190429688
      148.63165283203125,-133.67369079589844
      156.486083984375,-117.09211730957031
      165.21322631835938,-157.2369842529297
      173.06765747070312,-174.6912841796875
      185.28567504882812,-192.14556884765625
      195.75823974609375,-179.0548553466797
      202.73995971679688,-165.09141540527344
      207.10353088378906,-180.80027770996094
      220.19424438476562,-186.90928649902344
      224.55783081054688,-175.56399536132812
      228.92140197753906,-162.4732666015625
      238.52125549316406,-152.00070190429688
      262.0845642089844,-149.38255310058594
      288.2659912109375,-151.12799072265625
      304.8475646972656,-152.8734130859375
      311.82928466796875,-165.96412658691406
      320.5564270019531,-179.9275665283203
      333.64715576171875,-198.25457763671875
      364.1921691894531,-161.60055541992188
      374.66473388671875,-136.29183959960938
      380.77374267578125,-118.8375473022461
      392.1190185546875,-145.01898193359375
      399.1007385253906,-160.72784423828125
      408.7005920410156,-174.6912841796875
      423.5367431640625,-188.6547088623047
      427.90032958984375,-165.96412658691406
      436.6274719238281,-151.12799072265625
      451.463623046875,-141.5281219482422
      461.9361877441406,-129.31011962890625
      478.51776123046875,-145.01898193359375
      487.2449035644531,-130.18283081054688
      492.481201171875,-118.8375473022461
      501.2083435058594,-138.03726196289062
      508.1900634765625,-119.71025848388672
      520.4080810546875,-151.12799072265625
      528.2625122070312,-165.96412658691406
      537.8623657226562,-189.5274200439453
      577.134521484375,-181.67298889160156
      594.5888061523438,-153.74612426757812
      605.0614013671875,-171.20042419433594
      621.6429443359375,-159.85513305664062
      627.751953125,-148.5098419189453
      633.8609619140625,-136.29183959960938
      639.0972290039062,-129.31011962890625
      650.4425659179688,-115.34668731689453
      661.787841796875,-104.87410736083984
      678.369384765625,-150.25526428222656
      688.8419799804688,-186.03656005859375
      705.423583984375,-199.12728881835938
      721.1324462890625,-187.78199768066406
      729.8595581054688,-174.6912841796875
      735.9685668945312,-158.982421875
      748.1865844726562,-178.18212890625
      756.041015625,-190.400146484375
      770.0044555664062,-181.67298889160156
      776.1134643554688,-167.70956420898438
      783.9678955078125,-152.8734130859375
      797.9312744140625,-132.8009796142578
      801.4221801757812,-153.74612426757812
      810.1492919921875,-147.6371307373047
      813.64013671875,-134.54640197753906
      825.858154296875,-155.49156188964844
      838.076171875,-180.80027770996094
      857.27587890625,-196.50914001464844
      860.7667236328125,-178.18212890625
      870.3666381835938,-187.78199768066406
      876.4755859375,-175.56399536132812
      888.693603515625,-193.8909912109375
      911.3842163085938,-200.87271118164062
      913.129638671875,-183.41842651367188
      920.9840698242188,-165.96412658691406
      927.0930786132812,-150.25526428222656
      938.4383544921875,-171.20042419433594
      946.2927856445312,-190.400146484375
      967.2379150390625,-165.96412658691406
      974.2196655273438,-154.61883544921875
      984.6921997070312,-179.0548553466797
      996.9102172851562,-8.875507354736328
      -87.00128173828125,-14.984509468078613`
  },

  {
    type: 'mountains-3',
    labelX: -500, //hidden
    labelY: 0,
    labelRotation: 0,
    id: 'mountains-3',
    points:
      ` -75.65599060058594,-38.54780197143555
        -62.56527328491211,-124.94654846191406
        -55.583553314208984,-145.01898193359375
        -46.85641098022461,-128.43740844726562
        -45.11098098754883,-120.58296966552734
        -38.12926483154297,-116.21939849853516
        -33.765689849853516,-129.31011962890625
        -25.911258697509766,-115.34668731689453
        -18.056827545166016,-102.25596618652344
        -10.202397346496582,-118.8375473022461
        8.124608993530273,-96.14696502685547
        28.197044372558594,-75.20181274414062
        59.614768981933594,-38.54780197143555
        73.57820129394531,-99.63782501220703
        86.6689224243164,-87.4198226928711
        97.14149475097656,-73.45638275146484
        109.3594970703125,-61.238380432128906
        120.70478820800781,-48.14766311645508
        134.66822814941406,-41.16594696044922
        160.8496551513672,-94.40153503417969
        182.6675262451172,-61.238380432128906
        205.3581085205078,-95.27425384521484
        213.21253967285156,-108.3649673461914
        222.81239318847656,-124.0738296508789
        230.6668243408203,-132.8009796142578
        239.3939666748047,-119.71025848388672
        245.5029754638672,-112.7285385131836
        250.7392578125,-125.81925964355469
        260.339111328125,-113.60125732421875
        270.81170654296875,-104.00139617919922
        277.7934265136719,-117.09211730957031
        284.7751159667969,-108.3649673461914
        294.375,-99.63782501220703
        303.974853515625,-90.0379638671875
        314.4474182128906,-112.7285385131836
        321.42913818359375,-135.41912841796875
        337.13800048828125,-117.96482849121094
        345.8651428222656,-134.54640197753906
        353.7195739746094,-120.58296966552734
        369.4284362792969,-138.90997314453125
        384.26458740234375,-129.31011962890625
        393.86444091796875,-117.96482849121094
        403.4643249511719,-104.00139617919922
        413.0641784667969,-85.67439270019531
        504.69921875,-38.54780197143555
        523.0261840820312,-92.6561050415039
        527.3897705078125,-103.1286849975586
        533.498779296875,-118.8375473022461
        540.4805297851562,-136.29183959960938
        553.5712280273438,-124.0738296508789
        557.934814453125,-115.34668731689453
        562.29833984375,-106.61953735351562
        568.4073486328125,-117.96482849121094
        571.0255126953125,-108.3649673461914
        576.2617797851562,-103.1286849975586
        583.2435302734375,-92.6561050415039
        590.2252197265625,-83.92896270751953
        599.8250732421875,-70.83824157714844
        608.55224609375,-56.87480926513672
        670.5149536132812,-28.94794273376465
        682.7329711914062,-89.16524505615234
        687.0965576171875,-109.23768615722656
        693.20556640625,-124.0738296508789
        703.6781005859375,-107.49225616455078
        707.1690063476562,-125.81925964355469
        714.1506958007812,-143.2735595703125
        721.1324462890625,-131.0555419921875
        728.1141357421875,-144.14627075195312
        733.3504028320312,-130.18283081054688
        736.84130859375,-120.58296966552734
        743.822998046875,-137.16455078125
        756.9136962890625,-121.4556884765625
        766.5135498046875,-108.3649673461914
        776.9861450195312,-97.89239501953125
        790.9495849609375,-122.32839965820312
        806.658447265625,-97.89239501953125
        817.1310424804688,-81.3108139038086
        831.094482421875,-56.87480926513672
        843.3124389648438,-39.42051696777344
        890.4390258789062,-113.60125732421875
        900.91162109375,-131.0555419921875
        909.6387939453125,-143.2735595703125
        920.9840698242188,-131.0555419921875
        928.8385009765625,-141.5281219482422
        941.0565185546875,-127.56468963623047
        949.7836303710938,-111.85582733154297
        968.9833374023438,-129.31011962890625
        977.7105102539062,-111.85582733154297
        982.94677734375,-96.14696502685547
        988.18310546875,-46.4022331237793
        991.6739501953125,-20.220796585083008`
  },

  {
    type: 'foothills-0',
    labelX: -500, //hidden
    labelY: 0,
    labelRotation: 0,
    id: 'foothills-0',
    points:
      ` 12.488182067871094,-50.76580810546875
        22.960756301879883,-55.12937927246094
        29.069759368896484,-59.49295425415039
        42.16047668457031,-62.98381042480469
        54.378482818603516,-66.47467041015625
        70.08734130859375,-66.47467041015625
        82.30535125732422,-66.47467041015625
        92.77792358398438,-66.47467041015625
        102.3777847290039,-63.85652542114258
        111.97764587402344,-60.36566925048828
        120.70478820800781,-57.74752426147461
        135.5409393310547,-52.51123809814453
        151.2498016357422,-48.14766311645508
        180.04937744140625,-48.14766311645508
        265.5754089355469,-42.03866195678711
        288.2659912109375,-61.238380432128906
        301.3567199707031,-69.09281158447266
        312.7019958496094,-75.20181274414062
        329.2835693359375,-83.05624389648438
        342.3742980957031,-90.0379638671875
        357.21044921875,-93.52882385253906
        372.0466003417969,-94.40153503417969
        386.010009765625,-94.40153503417969
        399.1007385253906,-94.40153503417969
        413.9368896484375,-90.91067504882812
        429.645751953125,-88.29253387451172
        437.50018310546875,-85.67439270019531
        447.10003662109375,-78.69267272949219
        457.5726318359375,-74.3291015625
        468.0451965332031,-69.96553039550781
        477.6450500488281,-65.6019515991211
        488.11761474609375,-61.238380432128906
        498.5902099609375,-56.87480926513672
        511.680908203125,-49.89309310913086
        523.89892578125,-43.78409194946289
        534.3715209960938,-38.54780197143555
        672.2603759765625,-40.29323196411133
        698.4418334960938,-49.89309310913086
        708.0416870117188,-52.51123809814453
        716.7688598632812,-54.25666427612305
        728.1141357421875,-55.12937927246094
        735.9685668945312,-55.12937927246094
        747.3138427734375,-51.63852310180664
        756.041015625,-49.02037811279297
        770.0044555664062,-42.911376953125
        776.1134643554688,-36.802371978759766
        780.4769897460938,-26.32979965209961
        8.997323989868164,-22.838939666748047`
  },

  {
    type: 'foothills-1',
    labelX: -500, //hidden
    labelY: 0,
    labelRotation: 0,
    id: 'foothills-1',
    points:
      ` 276.0479736328125,-19.348081588745117
        290.01141357421875,-30.693370819091797
        309.2111511230469,-41.16594696044922
        337.13800048828125,-47.27494812011719
        352.84686279296875,-49.02037811279297
        365.06488037109375,-49.02037811279297
        381.6464538574219,-49.02037811279297
        403.4643249511719,-45.529518127441406
        418.30047607421875,-43.78409194946289
        429.645751953125,-40.29323196411133
        448.845458984375,-34.18423080444336
        463.6816101074219,-29.82065773010254
        502.9537658691406,-47.27494812011719
        509.0627746582031,-62.98381042480469
        514.299072265625,-73.45638275146484
        520.4080810546875,-78.69267272949219
        530.880615234375,-82.18353271484375
        541.3532104492188,-82.18353271484375
        551.8258056640625,-80.43810272216797
        560.5529174804688,-76.9472427368164
        569.2800903320312,-72.58367156982422
        581.4981079101562,-64.72924041748047
        597.2069702148438,-57.74752426147461
        623.3883666992188,-66.47467041015625
        643.4608154296875,-72.58367156982422
        666.1514282226562,-76.9472427368164
        676.6239624023438,-77.81996154785156
        691.4601440429688,-75.20181274414062
        703.6781005859375,-69.96553039550781
        712.4052734375,-62.98381042480469
        725.4959716796875,-53.383949279785156
        733.3504028320312,-47.27494812011719
        745.5684204101562,-38.54780197143555
        756.041015625,-33.31151580810547
        769.1317138671875,-29.82065773010254
        805.7857055664062,-41.16594696044922
        824.1127319335938,-57.74752426147461
        834.5853271484375,-69.96553039550781
        848.5487670898438,-85.67439270019531
        867.7484741210938,-93.52882385253906
        881.7119140625,-96.14696502685547
        899.1661987304688,-96.14696502685547
        913.129638671875,-97.0196762084961
        928.8385009765625,-97.89239501953125
        951.529052734375,-90.91067504882812
        974.2196655273438,-80.43810272216797
        988.18310546875,-59.49295425415039
        993.4193725585938,-31.566085815429688
        993.4193725585938,-15.857223510742188`
  },
  {
    type: 'foothills',
    labelX: -31, //hidden
    labelY: -40,
    labelRotation: 0,
    id: 'foothills',
    points:
      ` -88.74671173095703,-54.25666427612305
        -74.78327941894531,-62.98381042480469
        -59.07441329956055,-69.96553039550781
        -36.38383483886719,-75.20181274414062
        -22.420400619506836,-72.58367156982422
        -4.966109752655029,-69.96553039550781
        14.233611106872559,-69.09281158447266
        27.324329376220703,-63.85652542114258
        42.16047668457031,-61.238380432128906
        50.01490783691406,-57.74752426147461
        68.34191131591797,-53.383949279785156
        87.54163360595703,-50.76580810546875
        104.99592590332031,-47.27494812011719
        138.15908813476562,-55.12937927246094
        159.10423278808594,-62.98381042480469
        187.03109741210938,-71.7109603881836
        204.48538208007812,-73.45638275146484
        222.81239318847656,-74.3291015625
        253.35740661621094,-73.45638275146484
        265.5754089355469,-71.7109603881836
        282.156982421875,-66.47467041015625
        293.5022888183594,-61.238380432128906
        307.4656982421875,-54.25666427612305
        317.93829345703125,-49.02037811279297
        339.7561340332031,-37.675086975097656
        382.5191650390625,-21.0935115814209
        416.5550231933594,-21.96622657775879
        464.5543212890625,-49.02037811279297
        493.3539123535156,-57.74752426147461
        513.4263305664062,-59.49295425415039
        543.9713745117188,-49.02037811279297
        560.5529174804688,-64.72924041748047
        590.2252197265625,-75.20181274414062
        621.6429443359375,-73.45638275146484
        689.7147216796875,-56.00209426879883
        707.1690063476562,-43.78409194946289
        744.6957397460938,-35.05694580078125
        799.6767578125,-49.02037811279297
        835.4580078125,-59.49295425415039
        889.5663452148438,-67.34738159179688
        922.7294921875,-66.47467041015625
        952.4017944335938,-57.74752426147461
        974.2196655273438,-42.911376953125
        995.164794921875,-32.43880081176758
        996.9102172851562,-12.366365432739258
        -89.61942291259766,-15.857223510742188`
  }
]
