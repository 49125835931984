import React, { useContext } from 'react'
import { TownContext } from "./shared/townContext";
import Transactions from "./transactions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from "@fortawesome/pro-solid-svg-icons";

const Dashboard = () => {
  let { student, town } = useContext(TownContext)

  return (town && student) ? <div className="flex flex-col gap-x-4 w-full container mx-auto">
      <div className="border-b-4 border-yellow-500 rounded-sm shadow-md p-6">
        <div className='flex flex-row w-full'>
          <div className="w-3/4">
            <div className='flex flex-row gap-x-4 py-4'>
              {student.logo && <img src={student.logo} alt='student logo'
                                    className='w-28 h-30 rounded-xl object-cover object-center border-4 border-blue-900' />}
              <div className='flex flex-col gap-y-2'>
                <div className='flex flex-row gap-x-32'>
                  <p className='w-36 font-semibold'>Name</p>
                  <p>
                    {`${student.first_name} ${student.last_name}`}
                  </p>
                </div>
                <div className='flex flex-row gap-x-32'>
                  <p className='w-36 font-semibold'>Date of Birth</p>
                  <p>
                    {student.date_of_birth}
                  </p>
                </div>
                <div className='flex flex-row gap-x-32'>
                  <p className='w-36 font-semibold'>Birthplace</p>
                  <p>
                    {student.birthplace}
                  </p>
                </div>
                <div className='flex flex-row gap-x-32'>
                  <p className='w-36 font-semibold'>Nationality</p>
                  <p>
                    {student.nationality}
                  </p>
                </div>
              </div>
            </div>
            <div className='w-full py-6'>
              <h3 className='font-semibold'>Special Features</h3>
              <p>
                {student.special_features}
              </p>
            </div>
          </div>
          <div className='w-1/4 border-l-2 border-gray-300'>
            <div className='flex flex-row justify-end'>
              <a href={`/students/towns/${town.id}/profile/edit`} className='text-yellow-500'>
                <FontAwesomeIcon icon={faPen} /> Edit</a>
            </div>
            <div className='m-12 clear-right flex flex-col gap-y-2 items-center justify-center'>
              <h3 className='text-xl font-semibold'>Passport</h3>
              {student.logo && <img src={student.logo} alt='Placeholder Passport Image'
                                    className='w-52 h-28 rounded-xl object-cover object-center border-4 border-blue-900' />}
            </div>
          </div>
        </div>
      </div>
      <div className='my-4'>
        <div className='flex flex-row justify-between shadow-md items-center px-9 py-6 bg-blue-900 text-white'>
          <div className="border-b-2 border-yellow-500">Transactions</div>
        </div>
        <Transactions />
      </div>
  </div> : <></>
}
export default Dashboard
