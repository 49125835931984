import React from "react";

export default function AssetModal({ showModal, setShowModal, onConfirm, property, children, title }) {
  return (
    <>
      {showModal &&
      <>
        <div
          className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
          <div className="relative w-2/3 my-6 mx-auto">
            {/*content*/}
            <div
              className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*body*/}
              <div className="flex flex-col items-center relative p-6 flex-auto">
                {children}
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black" />
      </>
      }
    </>
  );
}
