import React, { useContext, useState } from "react";
import 'rc-slider/assets/index.css'
import Land from "./land/land";
import Livestock from "./items/livestock";
import Crops from "./items/crops";
import Goods from "./items/goods";
import { TownContext } from "../shared/townContext";

const PropertyDetails = ({ town }) => {
  let [tab, setTab] = useState('land')
  let { property } = useContext(TownContext)

  return (
    <div className='border-4 w-11/12 border-blue-900 py-3 px-6 rounded-lg'>
      <div className='flex flex-row border-b-2 border-gray-300 text-lg p-3'>
        <h3
          className={`uppercase cursor-pointer text-center p-3  ${tab === 'land' && 'border-b-2 border-yellow-500'}`}
          onClick={() => setTab('land')}
        >
          Land
        </h3>
        {property.land_type === 'county' && <>
          <h3
            className={`uppercase cursor-pointer text-center p-3 ${tab === 'livestock' && 'border-b-2' +
            ' border-yellow-500'}`}
            onClick={() => setTab('livestock')}
          >
            Livestock
          </h3>
          <h3
            className={`uppercase cursor-pointer text-center p-3 ${tab === 'crops' && 'border-b-2' +
            ' border-yellow-500'}`}
            onClick={() => setTab('crops')}
          >
            Crops
          </h3>
        </>}
        {property.land_type === 'town' && <h3
          className={`uppercase cursor-pointer text-center p-3 ${tab === 'goods' && 'border-b-2' +
          ' border-yellow-500'}`}
          onClick={() => setTab('goods')}
        >
          Goods
        </h3>}
      </div>
      {tab === 'land' && <Land town={town} />}
      {tab === 'businesses' && <div>Businesses</div>}
      {tab === 'livestock' && <Livestock town={town} />}
      {tab === 'crops' && <Crops town={town} />}
      {tab === 'goods' && <Goods town={town} />}
    </div>
  )
}

export default PropertyDetails
