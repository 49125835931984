import React, { useContext } from "react";
import houseIcon from "../../../../../assets/images/house9.png";
import StorageInfo from "./StorageInfo";
import axios from "axios";
import ManageLand from "./manageLand";
import { TownContext } from "../../shared/townContext";

const Land = ({ town }) => {
  let { property, setProperty, setNotice, setError } = useContext(TownContext)

  const buildHouse = (property) => {
    axios.get(`/students/towns/${town.id}/properties/buy_house?name=${property.name}`)
      .then(res => {
        setProperty(res.data)
        setNotice("House successfully bought!")
      })
      .catch(e => setError(e.response.data.errors));
  }

  const sellHouse = (property) => {
    if (property.owner) {
      axios.get(`/students/towns/${town.id}/properties/sell_house?name=${property.name}`)
        .then(res => {
          setProperty(res.data)
          setNotice("House successfully sold!")
        })
    }
  }

  const buyHouseStyling = (property, house) => {
    if (property.owner && house) {
      return 'bg-gray-200 text-gray-500 cursor-default'
    }

    if (property.owner && !house) {
      return 'bg-yellow-600 text-white'
    }

    if (!property.owner) {
      return 'bg-gray-200 text-gray-500 cursor-default'
    }
  }

  return (<>
    <div className='border-b-2 border-gray-300 py-3'>
      <div className='flex flex-row gap-x-2 my-3'>
        <img
          src={houseIcon}
          alt='House Icon'
          className='w-6 h-6 object-cover object-center'
        />
        <div className='w-6 h-6 bg-yellow-600'></div>
        <div className='w-6 h-6 bg-blue-900'></div>
        <div className='w-6 h-6 bg-yellow-600'></div>
      </div>
      <div className='flex flex-row gap-x-24'>
        <p className='font-semibold w-1/3'>Total Area</p>
        <p className='w-2/3'>{property.land_size} ft<sup>2</sup></p>
      </div>
      <div className='flex flex-row gap-x-24 mb-3'>
        <p className='font-semibold w-1/3'>Available Area</p>
        <p className='w-2/3'>{property.available_area} ft<sup>2</sup></p>
      </div>
      <div className='flex flex-row gap-x-24'>
        <p className='font-semibold w-1/3'>Total Area Used</p>
        <div className="w-2/3 relative pt-1">
          <div className="overflow-hidden h-2 mb-4 text-xs flex bg-yellow-200">
            <div style={{ width: `${property.used_area / property.land_size * 100}%` }}
                 className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-yellow-600"></div>
          </div>
        </div>
      </div>
      <div className='flex flex-row items-center'>
        {property.has_house ? <p className='font-semibold w-1/3'>House - ${property.house_size} ft<sup>2</sup></p> :
          <p className='font-semibold w-1/3'>House - Not Purchased</p>}
        <div className="w-1/2 ml-20 relative pt-1">
          <div className="overflow-hidden h-2 text-xs flex bg-blue-200">
            <div style={{ width: `${property.has_house ? 30 / 100 * 100 : 0}%` }}
                 className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-900" />
          </div>
        </div>
        <button
          disabled={!property.has_house}
          onClick={() => sellHouse(property)}
          className={`text-xl mx-4 font-bold px-3 py-1 rounded-full text-white ${property.has_house ? 'bg-blue-900' : 'bg-gray-400 cursor-default'} `}
        >$
        </button>
      </div>
    </div>
    <div className='border-b-2 border-gray-300 py-3'>
      <h3 className='text-yellow-600 mt-2 mb-4'>Existing Storage</h3>
      <div className='flex flex-col gap-y-1 mb-2'>
        {property.land_type === 'county' && <>
          <StorageInfo title={'Fence'} area={property.fence_land} totalArea={property.land_size} />
          <StorageInfo title={'Field'} area={property.field_land} totalArea={property.land_size} />
        </>}
        {property.land_type === 'town' &&
          <StorageInfo title={'Warehouse'} area={property.warehouse_land} totalArea={property.land_size} />}
      </div>
    </div>
    <ManageLand town={town} />
    <div className='flex flex-col gap-y-2 mt-6 uppercase items-center'>
      <button
        className={`w-full shadow-md p-2 ${buyHouseStyling(property, property.has_house)}`}
        disabled={!property.owner || property.has_house}
        onClick={() => buildHouse(property)}
      >
        Build House
      </button>
    </div>
  </>)
}

export default Land
