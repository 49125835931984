import React from "react"

const NavButton = ({ children, href }) => {
  return (
    <li>
      <a
        href={href}
        className='relative flex flex-row items-center h-11 focus:outline-none hover:bg-yellow-100 text-gray-600  border-l-4 border-opacity-0 hover:border-yellow-500 pr-6'
      >
        <span className='inline-flex justify-center items-center ml-4'/>
        <span className='ml-2 font-semibold text-sm tracking-wide truncate font-sans'>
          {children}
        </span>
      </a>
    </li>
  )
}

export default NavButton
