import React, { useContext } from "react";
import countyHouseIcon from "../../../../assets/images/house9.png";
import townHouseIcon from "../../../../assets/images/house1.png"
import { TownContext } from "../shared/townContext";

const PropertyLegend = ({ property }) => {
  let { house, fencedArea, fieldArea, warehouseArea } = useContext(TownContext)

  return (
    <div className='flex flex-row gap-x-2 my-6'>
      {house && property.land_type === 'county' && <img
        src={countyHouseIcon}
        alt='County House Icon'
        className='w-6 h-6 object-cover object-center'
      />}
      {house && property.land_type === 'town' && <img
        src={townHouseIcon}
        alt='Town House Icon'
        className='w-6 h-6 object-cover object-center'
      />}
      {fencedArea > 0 && <div className='w-6 h-6 bg-blue-900'></div>}
      {fieldArea > 0 && <div className='w-6 h-6 bg-yellow-600'></div>}
      {warehouseArea > 0 && <div className='w-6 h-6 bg-gray-900'></div>}
    </div>
  )
}

export default PropertyLegend
