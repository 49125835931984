import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import TownPage from "./townPage";
import Town from "./shared/townContext";
import Market from "../students/market/market";
import Leaderboards from "./leaderboards";
import { TownContext } from './shared/townContext';


const Main = () => {
  return (
    <Router>
      <Switch>
        <Route path='/teachers/towns/:townId/markets'>
          <Town>
            <Market context={TownContext} isTeacher={true} />
          </Town>
        </Route>
        <Route path='/teachers/towns/:townId/leaderboards'>
          <Town>
            <Leaderboards baseRoute={`/teachers`} />
          </Town>
        </Route>
        <Route path='/teachers/towns/:townId'>
          <Town>
            <TownPage />
          </Town>
        </Route>
      </Switch>
    </Router>
  )
}

export default Main
