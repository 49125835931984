import * as d3 from 'd3'
import * as _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import HouseImageUrban from '../../assets/images/house1.png'
import HouseImageRural from '../../assets/images/house9.png'
import * as utils from './utils'
import { defaultFeatures, townPlots } from './map/constants'
import { getFeatureColor, townFeatures } from "./map/features"

const Map = ({ town, plots, hideFeatures, hidePlots, handlePlotClick, onFeatureClick }) => {
  const elem = useRef()
  const items = plots

  useEffect(() => {
    const canvas = d3.select(elem.current)

    const getStorageColor = (type) => {
      switch (type) {
        case 'WAREHOUSE':
          return '#383838'
        // return Dirt
        case 'FENCE':
          return "#9c27b0"
        // return Crop
        case 'FIELD':
          return "#1976d2"
        // return Crop
        default:
          return '#efefef'
      }
    }

    const handlePlotMouseOver = function (d) {
      const plot = d3.select(this)

      plot.select('rect')
        .attr('fill', '#00aa00')
        .attr('stroke', '#00aa00')
        .attr('stroke-width', 3)
      // dispatch({ type: 'VIEW_PLOT_DETAILS', payload: d })
    }

    const getPlotColor = (d) => {
      if (d.owned_by_me) {
        return '#b44c00';
      } else if (d.owner_id) {
        return '#999';
      } else {
        return '#efefef';
      }
    }

    const handlePlotMouseOut = function (d) {
      d3.select(this).select('rect')
        // .transition()
        .attr('fill', getPlotColor)
        .attr('stroke', '#fff')
        .attr('stroke-width', 1.5)
    }

    const renderPlot = function (d) {
      const plot = d3.select(this)

      const g = plot
        .insert('svg:g')
        .style('pointer-events', 'all')
        .attr('transform', d => `${d.rotation ? `rotate(${d.rotation}, ${d.x + (d.w / 2)}, ${d.y + (d.h / 2)})` : ''} translate(${d.x}, ${d.y})`)
        .attr('class', 'plot')
        .on('mouseover', handlePlotMouseOver)
        .on('mouseout', handlePlotMouseOut)
        .on('click', handlePlotClick)

      g.append('circle')
        .classed('owner', true)
        .attr('cx', d.w / 2)
        .attr('cy', d.h + 2.5)
        .style('pointer-events', 'none')
        .transition()
        .attr('r', 5)
        .style('opacity', 0.9)

      g.append('svg:rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', d => d.w)
        .attr('height', d => d.h)
        .attr('fill', getPlotColor)
        .attr('stroke', '#fff')
        .attr('stroke-width', 1.5)
        .style('opacity', 0.85)


      g.append('text')
        .attr('x', (d.w / 2))
        .attr('y', (d.h / 2))
        .attr('text-anchor', 'middle')
        .attr('font-size', '1rem')
        .attr('font-family', 'Josefin Sans, sans-serif')
        .attr('font-variant', 'all-small-caps')
        .attr('font-weight', '700')
        .attr('fill', '#000')
        .style('pointer-events', 'none')
        .classed('plot-hover-label', true)
        .text(d.plotId)
    }

    const updatePlot = function (d) {
      const plot = d3.select(this)

      let storage = plot.selectAll('.storage')
      let nodes = storage
      if (d.storage) {
        const area = utils.getPlotArea(d)
        let lastXPos = d.w - 5
        nodes = nodes.data(d.storage).enter()
          .insert('rect')
          .attr('id', (x, i) => `a${d.id}-${i}`)
          .classed('storage', true)
          .style('opacity', 0.8)
          .attr('y', 5)
          .style('pointer-events', 'none')

        plot.selectAll('.storage').each((n, i) => {
          if (n.size === 0) { return }
          d3.select(this).select(`#a${d.id}-${i}`)
            .attr('x', () => {
              lastXPos = lastXPos - 1 * ((d.w - 10) * (n.size / area))
              return lastXPos
            })
            .attr('height', d.h - 10)
            .attr('width', (d.w - 10) * (n.size / area))
            .attr('fill', getStorageColor(n.storage_type))
            .style('opacity', 0.5)
        })

        nodes
          .exit()
          .transition()
          .attr('width', 0)
          .remove()
      } else {
        nodes.remove()
      }

      let house = plot.select('.house')

      if (d.has_house) {
        plot.insert('image')
          .classed('house', true)
          .attr('x', -5)
          .attr('y', -5)
          .attr('href', () => d.type === 'rural' ? HouseImageRural : HouseImageUrban)
          .attr('transform', `${d.rotation ? `rotate(${-(d.rotation)})` : ''}`)
          .style('pointer-events', 'none')
          .transition()
          .attr('width', 30)
          .attr('height', 30)
      } else {
        house
          .transition()
          .attr('width', 0)
          .attr('height', 0)
          .remove()
      }

      // plot.select('.owner')
      //   .attr('fill', d.owner === currentUser.username ? '#00af00' : (d.owner ? '#cdcdcd' : '#af00af'))

    }

    const setFeatureText = (d) => {
      return d.featureName || d.id
    }

    if (!hideFeatures) {
      const mergedFeatures = townFeatures.map(feature => ({ ...feature, ..._.find(defaultFeatures, { featureId: feature.id }) }))
      const features = canvas
        .selectAll('g.feature')
        .data(mergedFeatures)
        .enter()
        .append('svg:g')
        .attr('class', 'feature')
        .style('pointer-events', 'all')
        // On hover color change isn't working properly. Commenting it out for later since this is not a high priority.
        // .on('mouseover', function (d, i, a) {
        //   d3.select(this).select('polygon').attr('fill', getFeatureColor(d, i, a, true))
        // })
        // .on('mouseout', function (d, i, a) {
        //   d3.select(this).select('polygon').attr('fill', getFeatureColor(d, i, a))
        // })
        .on('click', onFeatureClick)

      features
        .insert('svg:polygon')
        .attr('points', d => d.points)
        .attr('fill', getFeatureColor)
        .style('pointer-events', 'all')

      // featurePolygons.select('text')
      const featureText = canvas.selectAll('g.feature').select('.featureText')
      if (featureText.empty()) {
        features
          .append('text')
          .attr('class', `featureText`)
          .attr('font-size', '13px')
          .attr('font-family', 'Josefin Sans, sans-serif')
          .attr('font-variant', 'all-small-caps')
          .attr('fill', '#888')
          .attr('x', d => d.labelX)
          .attr('y', d => d.labelY)
          .attr('text-anchor', 'middle')
          .attr('transform', d => d.labelRotation ? `rotate(${d.labelRotation}, ${d.labelX},${d.labelY})` : '')
          .style('pointer-events', 'none')
          .text(setFeatureText)
      } else {
        featureText.text(setFeatureText)
      }

      features
        .exit()
        .remove()
    }

    if (!hidePlots) {
      const mergedPlots = townPlots.map(plot => ({ ..._.find(items, { name: plot.plotId }), ...plot }))
      const plots = canvas
        .selectAll('g.plot')
        .data(mergedPlots)
        .enter()
        .each(renderPlot)

      canvas
        .selectAll('g.plot')
        .each(updatePlot)

      plots
        .exit()
        .remove()
    }

    //Town Name

    const townName = canvas.select('#town-name')
    if (townName.empty()) {
      canvas
        .append('text')
        .attr('id', 'town-name')
        .attr('font-size', '17px')
        .attr('font-family', 'Josefin Sans, sans-serif')
        .attr('font-weight', '700')
        .attr('font-variant', 'all-small-caps')
        .attr('x', 393)
        .attr('y', 625)
        .attr('fill', '#888')
        .attr('text-anchor', 'middle')
        .style('pointer-events', 'none')
        .text(town.name)
    } else {
      townName.text(town.name)
    }

    //County Name
    const countyName = canvas.select('#county-name')
    if (countyName.empty()) {
      canvas
        .append('text')
        .attr('id', 'county-name')
        .attr('font-size', '17px')
        .attr('font-family', 'Josefin Sans, sans-serif')
        .attr('font-weight', '700')
        .attr('font-variant', 'all-small-caps')
        .attr('fill', '#888')
        .attr('x', 545)
        .attr('y', 60)
        .attr('text-anchor', 'middle')
        .style('pointer-events', 'none')
        .text(town.countyName)
    } else {
      countyName.text(town.countyName)
    }

    // canvas.on('mousedown', () => {
    //   const [x, y] = d3.mouse(elem.current)
    //   canvas.insert('circle').attr('cx', x).attr('cy', y).attr('r', 5)
    //   console.log(`${x},${y}`)
    // })
  }, [town, hideFeatures, hidePlots, onFeatureClick, items])

  return (
    <>
      <svg ref={elem} viewBox='-100 -200 1100 1275' className='w-full' />
    </>
  )
}

export default Map
