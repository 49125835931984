import React from "react"

const NavButtonHeader = ({ children }) => {
  return (
    <li className='px-5'>
      <div className='flex flex-row items-center h-8'>
        <div className='flex font-semibold text-sm text-gray-700 my-4 font-sans uppercase'>
          {children}
        </div>
      </div>
    </li>
  )
}

export default NavButtonHeader
