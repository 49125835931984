import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Slider from "rc-slider";
import 'rc-slider/assets/index.css';
import { formatPrice } from "../../../shared/utils";
import { capitalize } from "lodash";
import { TownContext } from "../../shared/townContext";
import MarketButton from "../../market/marketButton";

const SellAssetItem = ({ name, sellEndpoint, marketPriceEndpoint, step, initialCount }) => {
  const prettyName = capitalize((name))
  let [currentPrice, setCurrentPrice] = useState(0)
  let [sellCount, setSellCount] = useState(0)
  let { town, property, setProperty, setNotice, setError } = useContext(TownContext)
  let [assetCount, setAssetCount] = useState(initialCount)

  const handleSell = () => {
    axios.post(sellEndpoint, {
      item: {
        count: sellCount,
        name: name
      }
    }).then((res) => {
      setProperty(res.data)
      setAssetCount(prev => prev - sellCount)
      setSellCount(0)
      setNotice(`${sellCount} ${name} items successfully sold!`)
    }).catch(e => {
      setError(e.response.data.errors)
    })
  }

  useEffect(() => {
    axios.get(marketPriceEndpoint).then(res => {
      setCurrentPrice(res.data[step - 1])
    })
  }, [])


  return (
    <>
      {assetCount > 0 && <div className="flex flex-row justify-between items-center gap-x-2">
        <div className='w-36 flex flex-col text-xs'>
          <p>{`${prettyName} - ${assetCount}`} items</p>
          <p className='text-yellow-600'>{formatPrice(currentPrice)}/each</p>
        </div>
        <p className='w-36 text-xs text-center font-semibold'>{formatPrice(sellCount * currentPrice)}</p>
        <div className="w-2/5">
          <Slider
            min={0}
            value={sellCount}
            max={assetCount}
            trackStyle={{ backgroundColor: "#1E3A8A", height: "0.5rem" }}
            handleStyle={{
              height: "1rem",
              width: "1rem",
              backgroundColor: "white",
            }}
            railStyle={{ backgroundColor: "#BFDBFE", height: "0.5rem" }}
            onChange={value => setSellCount(value)}
          />
        </div>
        <MarketButton town={town} onClick={handleSell} className='w-36 text-xs shadow-md p-2 my-1 text-white'>
          Sell {sellCount} {prettyName}
        </MarketButton>
      </div>
      }
    </>
  )
}

export default SellAssetItem
