import React, { useState } from "react";
import PriceChart from "./priceChart";
import CropsRow from "./cropRow";
import { capitalize } from "lodash";
import MarketButton from "./marketButton";

const MarketCropsItem = ({ type, properties, town, propertyType, isTeacher, icon }) => {
  let [showOptions, setShowOptions] = useState(false)

  function pricingRoute(type) {
    if(!isTeacher) {
      return `/students/towns/${town.id}/markets/crop_prices?name=${type}`;
    }
    return `/teachers/towns/${town.id}/markets/crop_prices?name=${type}`;
  }

  const propertyList = (properties, type, town) => {
    return properties && properties.map(property => <CropsRow key={property.id} property={property} type={type}
                                                              town={town} propertyType={propertyType} />)
  }

  return (<>
    <div className='flex flex-row gap-x-6 items-center'>
      <img className="w-16" src={icon}/>
      <p className='w-36'>{capitalize(type)}</p>
      <PriceChart type={type} pricingRoute={pricingRoute(type)} />
      {!isTeacher && <MarketButton town={town} onClick={() => setShowOptions(true)}>Buy</MarketButton>}
    </div>
    {showOptions && <div className='border-2 p-3 border-gray-200 rounded-md'>
      <div className='flex flex-row justify-between items-center my-2 border-b border-gray-900 py-2'>
        <h3 className='font-semibold'>Buy {type}</h3>
        <button
          className='shadow-md px-2 py-1 rounded-md bg-blue-900 text-white'
          onClick={() => setShowOptions(false)}
        >Close
        </button>
      </div>
      <div className='flex flex-col gap-y-4'>
        {propertyList(properties, type, town, propertyType)}
      </div>
    </div>}
  </>)
}

export default MarketCropsItem
