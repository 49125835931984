import React, { useContext } from "react";
import axios from "axios";
import { TownContext } from "../students/shared/townContext";
import { formatPrice } from "./utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

export default function Modal({ showModal, setShowModal, children, title, town }) {
  let { property, setProperty, error, setError, notice, setNotice } = useContext(TownContext)

  const buyProperty = (property) => {
    if (property.owner === null) {
      axios.get(`/students/towns/${town.id}/properties/buy?name=${property.name}`)
        .then(res => {
          setProperty(res.data)
          setNotice('Property successfully bought!')
        })
        .catch(e => {
          setError(e.response.data.errors)
        })
    }
  }

  const sellProperty = (property) => {
    if (property.owner) {
      if (window.confirm("This will sell everything on the property, are you sure you want to continue?")) {
        axios.get(`/students/towns/${town.id}/properties/sell?name=${property.name}`)
          .then(res => {
            setProperty(res.data)
            setNotice('Property successfully sold!')
          })
      }
    }
  }

  return (
    <>
      {showModal &&
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none"
          >
            <div className="relative w-2/3 my-6 mx-auto">
              {/*content*/}
              <div
                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div
                  className="flex flex-row justify-between p-5 border-b border-solid border-blueGray-200 rounded-t items-center">
                  <h3 className="text-xl font-semibold">
                    {title}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-grey-800 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <FontAwesomeIcon icon={faTimes} className='h-8' />
                  </button>
                </div>
                {/*body*/}
                <div className="flex flex-row relative p-6 flex-auto">
                  {children}
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className={`font-bold uppercase text-sm px-6 py-3 shadow mb-1 mr-1 outline-none rounded ${!property.owned_by_me ? 'bg-gray-200 text-gray-400 cursor-default' : 'bg-blue-900 text-white hover:shadow-lg focus:outline-none ease-linear transition-all duration-150'} `}
                    type="button"
                    disabled={!property.owned_by_me}
                    onClick={() => sellProperty(property)}
                  >
                    Sell ({formatPrice(property.price)})
                  </button>
                  <button
                    className={`font-bold uppercase text-sm px-6 py-3 shadow mb-1 mr-1 outline-none rounded ${property.owner ? 'bg-gray-200 text-gray-400 cursor-default' : 'bg-blue-900 text-white hover:shadow-lg focus:outline-none ease-linear transition-all duration-150'} `}
                    type="button"
                    disabled={property.owner}
                    onClick={() => buyProperty(property)}
                  >
                    Buy ({formatPrice(property.price)})
                  </button>
                  <button
                    className="bg-blue-900 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-30 bg-black" />
        </>
      }
    </>
  );
}
