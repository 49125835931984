import React from 'react'
import NavButton from "./navButton"
import NavButtonHeader from "./navButtonHeader"
import NavLogo from "./navLogo"
import NavSignOut from "./navSignOut"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faSignOut, faUserGraduate, faChalkboardTeacher, faUserShield, faBuilding } from '@fortawesome/pro-solid-svg-icons'

const Sidenav = ({current_admin}) => {
  return (
    <div>
      <div className='flex lg:flex-col flex-auto text-gray-800'>
        <div className='lg:fixed flex flex-col top-0 left-0 w-full lg:w-64 h-full shadow-lg'>
          <NavLogo/>
          <div className=' overflow-y-auto overflow-x-hidden flex-grow'>
            <ul className='flex flex-row md:flex-col py-6 space-y-1'>
              <div>
                <NavButtonHeader>
                  Dashboard
                </NavButtonHeader>
                <NavButton href={'/admin/admin_users'}>
                  <FontAwesomeIcon
                    icon={faUserShield}
                    className='mx-1 text-sm'
                  />
                  Admins
                </NavButton>
                <NavButton href={'/admin/teachers'}>
                  <FontAwesomeIcon
                    icon={faChalkboardTeacher}
                    className='mx-1 text-sm'
                  />
                  Teachers
                </NavButton>
                <NavButton href={'/admin/students'}>
                  <FontAwesomeIcon
                    icon={faUserGraduate}
                    className='mx-1 text-sm'
                  />
                  Students
                </NavButton>
                <NavButton href={'/admin/towns'}>
                  <FontAwesomeIcon
                    icon={faBuilding}
                    className='mx-1 text-sm'
                  />
                  Towns
                </NavButton>
              </div>
              <div>
                <NavButtonHeader>
                  Settings
                </NavButtonHeader>
                <NavButton href={`/admin/admin_users/${current_admin.id}/edit`}>
                  <FontAwesomeIcon
                    icon={faCog}
                    className='mx-1 text-sm'
                  />
                  Settings
                </NavButton>
                <NavSignOut href={'/admin_users/sign_out'}>
                  <FontAwesomeIcon
                    icon={faSignOut}
                    className='mx-1 text-sm text-red-700'
                  />
                  Logout
                </NavSignOut>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidenav
