import React, { useContext } from "react";
import SellCrops from "./sellCrops";
import { TownContext } from "../../shared/townContext";

const Crops = ({ town }) => {
  let { property } = useContext(TownContext)

  let cropsCount = Object.values(property.crops_owned).reduce((a, b) => a + b)
  let usage = property.field_land > 0 ? parseInt(cropsCount / property.field_land * 100) : 0

  return (
    <div>
      <div>
        <div className='border-b-2 border-gray-200 py-6 my-4 text-sm'>
          <div className='flex flex-col gap-y-2'>
            <div className='flex flex-row gap-x-24'>
              <p className='font-semibold w-56'>Plot</p>
              <p className="w-2/3">{property.name}</p>
            </div>
            <div className='flex flex-row gap-x-24'>
              <p className='font-semibold w-56'>Total Storage</p>
              <p className="w-2/3">{property.field_land} items</p>
            </div>
            <div className='flex flex-row gap-x-24'>
              <p className='font-semibold w-56'>Market Storage</p>
              <p className="w-2/3">{cropsCount} items</p>
            </div>
            <div className='flex flex-row gap-x-24'>
              <p className='font-semibold w-56'>Purchasing Power Storage</p>
              <p className="w-2/3">example-250 items</p>
            </div>
            <div className='flex flex-row gap-x-24'>
              <p className='font-semibold w-56'>Total Storage Used - {usage}%</p>
              <div className="w-2/3 relative pt-1">
                <div className="overflow-hidden h-2 mb-4 text-xs flex bg-blue-200">
                  <div style={{ width: `${usage}%` }}
                       className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-900"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='border-4 border-blue-900 rounded-xl my-3 p-3'>
          {cropsCount > 0 ? <div>
            <p>Usage - {usage}%</p>
            <div className="w-2/3 relative pt-1">
              <div className="overflow-hidden h-2 mb-4 text-xs flex bg-blue-200">
                <div style={{ width: `${usage}%` }}
                     className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-900"></div>
              </div>
            </div>
            <h3 className='text-yellow-600'>Crop Assets</h3>
            <SellCrops town={town} name={'wheat'} prettyName={"Wheat"} />
            <SellCrops town={town} name={'barley'} prettyName={"Barley"} />
            <SellCrops town={town} name={'canola'} prettyName={"Canola"} />
          </div> : <h3>You don't own any crops yet!</h3>}
        </div>
      </div>
    </div>
  )
}

export default Crops
