import React, { useContext, useState } from "react";
import Slider from "rc-slider";
import 'rc-slider/assets/index.css';
import { formatPrice } from "../../shared/utils";
import { TownContext } from "../shared/townContext";

const BuyItems = ({ type, setShowModal, currentPrice, availableLand, buyItems, property }) => {
  let [count, setCount] = useState(0)
  let { setNotice, setError } = useContext(TownContext)

  const buy = (count, type, property) => {
    buyItems(count, type, property)
      .then(res => {
        setShowModal(false)
        setNotice(`${count} ${type} successfully bought! `)
      })
      .catch(e => {
        setError(e.response.data.errors)
      });
  }

  return (
    <div>
      <h3 className='text-center text-2xl m-6'>Buy Assets for {property.name} - {type}</h3>
      <div className='w-full flex flex-col items-center gap-y-4'>
        <p>Use the slider below to choose how many {type} that you want to buy.</p>
        <div className='flex flex-row items-end gap-x-2'>
          <p className='font-semibold uppercase text-3xl'>{count}</p>
          <p className='font-semibold uppercase text-2xl'>{type}</p>
        </div>
        <div className='flex flex-row items-end gap-x-2 text-yellow-600'>
          <p className='font-semibold uppercase text-1xl'>Market Value</p>
          {currentPrice && <p className='font-semibold uppercase text-2xl'>{formatPrice(currentPrice)}/{type}</p>}
        </div>
        <div className='w-3/4'>
          {availableLand && <Slider
            min={0}
            max={availableLand}
            value={count}
            onChange={value => setCount(value)}
            railStyle={{ backgroundColor: '#BFDBFE', height: '0.5rem' }}
            trackStyle={{ backgroundColor: '#1E3A8A', height: '0.5rem' }}
            handleStyle={{
              height: '1rem',
              width: '1rem',
              backgroundColor: 'white',
            }}
          />}
        </div>
        <div className='flex flex-row items-end gap-x-2 text-yellow-600'>
          <p className='font-semibold uppercase text-1xl'>Total Cost</p>
          <p className='font-semibold uppercase text-2xl'>{currentPrice && formatPrice(currentPrice * count)}</p>
        </div>
        <div className='flex flex-row gap-x-2'>
          <button
            onClick={() => buy(count, type, property)}
            disabled={!property?.owner_id || count === 0}
            className={`shadow-md p-2 w-36 my-1 rounded-md ${property?.owner_id ? 'bg-yellow-600 text-white' : 'bg-gray-200' +
              ' text-gray-500' +
              ' cursor-default'}`}>Buy
          </button>
          <button
            onClick={() => setShowModal(false)}
            className={`shadow-md p-2 w-36 my-1 rounded-md bg-blue-900 text-white`}>Close
          </button>
        </div>
      </div>
    </div>
  )
}

export default BuyItems

