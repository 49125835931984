import React, { useContext } from "react";
import { TownContext } from "../shared/townContext";
import { formatPrice } from "../../shared/utils";

const StudentHeader = () => {
  let { student, studentLogo } = useContext(TownContext)

  return (
    student &&
    <div className='flex flex-row gap-x-2 bg-blue-900 rounded-xl px-4 py-3'>
      <img
        src={studentLogo}
        alt='Student Image'
        className='w-20 h-12 rounded-xl object-cover object-center'
      />
      <div className='flex flex-col'>
        <div className='flex flex-row gap-x-1 text-white font-semibold'>
          <p>{student.first_name}</p>
          <p>{student.last_name}</p>
        </div>
        <p className='text-yellow-600'>{formatPrice(student.current_balance)}</p>
      </div>
    </div>
  )
}

export default StudentHeader
