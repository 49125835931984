import React, { useContext } from "react";
import SellAssetItem from "./sellAssetItem";
import { TownContext } from "../../shared/townContext";

const SellCrops = ({ town, name }) => {
  let { property } = useContext(TownContext)
  const sellEndpoint = `/students/towns/${town.id}/properties/${property.id}/crops/sell`
  const marketPriceEndpoint = `/students/towns/${town.id}/markets/crop_prices?name=${name}`

  return (<>
    <SellAssetItem name={name} sellEndpoint={sellEndpoint} marketPriceEndpoint={marketPriceEndpoint}
                   step={town.step} initialCount={property.crops_owned[name]} />
  </>);
}

export default SellCrops
