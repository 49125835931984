import React, { useContext } from "react";
import SellLandItem from "./sellLandItem";
import { TownContext } from "../../shared/townContext";

const SellLand = ({ town }) => {
  let { property } = useContext(TownContext)

  return (<>
    {property.land_type === 'county' && <>
      <SellLandItem town={town} type={'fences'} maxArea={property.available_fence_land} />
      <SellLandItem town={town} type={'fields'} maxArea={property.available_field_land} />
    </>}
    {property.land_type === 'town' && <>
      <SellLandItem town={town} type={'warehouses'} maxArea={property.available_warehouse_land} />
    </>}
  </>)
}

export default SellLand
