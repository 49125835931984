import React, { useContext } from 'react'
import Map from "./Map";
import { TownContext } from "./shared/townContext";
import { formatPrice } from "../shared/utils";
import axios from "axios";

const TownPage = () => {
  let { town, setTown, teacher, townLogo } = useContext(TownContext)

  const toggleMarketOpen = () => {
    axios.get(`/teachers/towns/${town.id}/toggle_market_open`).then(res => setTown(res.data))
  }

  return (town && teacher) ? <div className="flex flex-row w-full">
    <div className="flex flex-col p-8 bg-gray-100 w-128 justify-between">
      <div className="flex flex-col mb-8 gap-y-2">
        <h1 className="text-xl font-bold text-center w-full mb-8">
          {`${teacher.salutation} ${teacher.first_name} ${teacher.last_name}`}
        </h1>
        <div>
          {townLogo && <img src={townLogo} alt='town logo' className='w-64' />}
        </div>
        <div className="text-xl font-bold w-full text-center">
          {formatPrice(town.net_worth)}
        </div>
        <div>
          {town.num_students} students in town
        </div>
        {town.step < 23 && <a href={`/teachers/towns/${town.id}/next_step`} data-method='post' className='btn btn-primary'>{'Go to Next Step >'}</a>}
        {town.step > 1 && <a href={`/teachers/towns/${town.id}/previous_step`} data-method='post' className='btn btn-primary'>{'< Go to Previous Step'}</a>}
        <div
          className={`${town.is_market_open ? 'bg-green-700' : 'bg-red-700'} cursor-pointer btn bg-indigo-400 hover:bg-indigo-900`}
          onClick={toggleMarketOpen}>
          <span>{town.is_market_open ? 'Close' : 'Open'} Market</span></div>

      </div>
    </div>
    <div className="p-8 flex flex-1">
      <Map />
    </div>
  </div> : <></>
}

export default TownPage
