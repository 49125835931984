import React, { useContext } from 'react'
import Map from "./Map";
import { TownContext } from "./shared/townContext";
import { formatPrice } from "../shared/utils";

const TownPage = () => {
  let { student, town, townLogo } = useContext(TownContext)

  return (student && town) ? <div className="flex flex-row w-full gap-x-4">
    <div className="flex flex-col bg-gray-100 p-8 justify-between w-1/4">
      <div>
        <div className="flex flex-col mb-8">
          <h1 className="text-xl font-bold text-center w-full mb-8">
            {town.name}
          </h1>
          <div>
            {townLogo && <img src={townLogo} alt='town logo' className='w-64' />}
          </div>
          <div className="text-xl font-bold w-full text-center">
            {formatPrice(student.current_balance)}
          </div>
        </div>
        <div>
          Students in Town: {town.num_students}
        </div>
        <div>
          Properties Owned: {town.num_properties}
        </div>
      </div>
      <div>{`${student.first_name} ${student.last_name}`}</div>
    </div>
    <div className="flex flex-1">
      <Map />
    </div>
  </div> : <></>
}

export default TownPage
