import React from "react"

const NavLogo = () => {
  return <div className="flex items-center pl-6 h-20">
    <a href="/admin/">
      TOWN
    </a>
  </div>
}

export default NavLogo
