import React, { useContext, useEffect, useState } from "react";
import Slider from "rc-slider";
import 'rc-slider/assets/index.css';
import axios from "axios";
import { capitalize } from "lodash/string";
import { TownContext } from "../../shared/townContext";

const LandItem = ({ type, action, maxArea, route }) => {
  let { property, setProperty, setNotice, setError } = useContext(TownContext)
  let [size, setSize] = useState(0)

  const buildLand = (size) => {
    axios.post(route, { size: size })
      .then(res => {
        setProperty(res.data)
        setSize(0)
        if (action === 'buy') {
          setNotice(`${size} ${type} successfully bought!`)
        }
        if (action === 'sell') {
          setNotice(`${size} ${type} successfully sold!`)
        }
      })
      .catch(e => {
        setError(e.response.data.errors)
      })
  }

  useEffect(() => {
    console.log(size)
  }, [size]);

  return (
    <div className='flex flex-row items-center justify-between gap-x-3'>
      <p className='w-48 font-semibold'>{capitalize(type)} - {size} ft<sup>2</sup></p>
      <div className='flex flex-row items-center w-full gap-x-2'>
        <div className='w-3/5'>
          <Slider
            min={0}
            max={maxArea}
            trackStyle={{ backgroundColor: '#1E3A8A', height: '0.5rem' }}
            handleStyle={{
              height: '1rem',
              width: '1rem',
              backgroundColor: 'white',
            }}
            value={size}
            railStyle={{ backgroundColor: '#BFDBFE', height: '0.5rem' }}
            onChange={value => {
              setSize(value)
            }}
          />
        </div>
        <button
          onClick={() => buildLand(size)}
          disabled={!property.owner || size <= 0}
          className={`shadow-md p-2 w-28 my-1 ${(property.owner && size > 0) ? 'bg-yellow-600 text-white' : 'bg-gray-200' +
            ' text-gray-500' +
            ' cursor-default'}`}>{capitalize(action)} {type}
        </button>
      </div>
    </div>
  )
}

export default LandItem
