import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import TownPage from "./townPage";
import Town, { TownContext } from "./shared/townContext";
import Dashboard from "./dashboard";
import Market from "./market/market";
import Leaderboards from "./leaderboards";

const Main = () => {
  return <Router>
    <Switch>
      <Route path='/students/towns/:townId/leaderboards'>
        <Town>
          <Leaderboards baseRoute={`/students`} />
        </Town>
      </Route>
      <Route path='/students/towns/:townId/markets'>
        <Town>
          <Market context={TownContext} />
        </Town>
      </Route>
      <Route path='/students/towns/:townId/students'>
        <Town>
          <Dashboard />
        </Town>
      </Route>
      <Route path='/students/towns/:townId'>
        <Town>
          <TownPage />
        </Town>
      </Route>
    </Switch>
  </Router>
}

export default Main
