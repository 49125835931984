import React from "react";
import { capitalize } from "lodash";
import { formatPrice } from "../../shared/utils";

const PropertySidebar = ({ property }) => {

  return (
    <div className='my-3 flex flex-col gap-y-1'>
      <h3 className='font-semibold'>Land Description</h3>
      <p>{property.name}</p>
      <h3 className='font-semibold'>Land Value</h3>
      <p>{formatPrice(property.price)}</p>
      <h3 className='font-semibold'>Land Type</h3>
      <p>{capitalize(property.land_type)}</p>
      <h3 className='font-semibold'>Land Area</h3>
      <p>{property.land_size} ft<sup>2</sup></p>
      <h3 className='font-semibold'>Net Worth</h3>
      <p>{formatPrice(property.net_worth)}</p>
      <h3 className='font-semibold'>Assets</h3>
      <div className="relative pt-1">
        <div className="overflow-hidden h-2 text-xs flex bg-blue-200">
          <div style={{ width: `${property.asset_count/property.land_size * 100}%` }}
               className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-900"></div>
        </div>
      </div>
      <h3 className='font-semibold'>Asset Summary</h3>
      <p>{property.asset_count }/{property.land_size} items</p>
    </div>
  )
}

export default PropertySidebar
