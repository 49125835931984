import React, { useContext } from 'react'
import { TownContext } from "./shared/townContext";
import TownLeaderboard from "../shared/leaderboards/townLeaderboard";
import TownsLeaderboard from "../shared/leaderboards/townsLeaderboard";
import AllTownsLeaderboard from "../shared/leaderboards/allTownsLeaderboard";

const Leaderboards = ({ baseRoute}) => {
  let { town } = useContext(TownContext)

  return town ? <div className="flex flex-col w-full">
    <h1 className="text-xl font-bold mb-8">Leaderboards</h1>
    <div className='flex flex-row gap-x-4 w-full'>
      <TownLeaderboard town={town} baseRoute={baseRoute}/>
      <TownsLeaderboard town={town} baseRoute={baseRoute} />
      <AllTownsLeaderboard town={town} baseRoute={baseRoute} />
    </div>
  </div> : <></>
}

export default Leaderboards
